import { ConnectWallet } from "@thirdweb-dev/react";
import { useProSidebar } from "react-pro-sidebar";
import MenuOpen from "@material-ui/icons/MenuOpen";
import Menu from "@material-ui/icons/Menu";
import { Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { Contracts } from "../../contractsProvider";
import { Info } from "@material-ui/icons";
import { Tooltip } from 'react-tooltip';
import "./index.css";
import 'react-tooltip/dist/react-tooltip.css';

const TaskBar = (props) => {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken } = useProSidebar();
    const {daiTotal, z3Total, treasuryTotal} = useContext(Contracts);

    const toggleHandler = () => {
        if (broken) {
            toggleSidebar();
        }
        else {
            collapseSidebar();
        }
    }

    return (
        <div className="Header">
            <div className="logo-text">
                <div className="expand-menu">
                    {collapsed || broken ? <Menu onClick={toggleHandler}></Menu> : <MenuOpen onClick={toggleHandler}></MenuOpen>}
                </div>
                
                <div className="treasury">
                    <div className="treasury-container">
                        <div className="stats-container">
                            <Typography component={'span'}>Current Treasury&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                        </div>
                        <div className="stats-container stats-value" data-tooltip-id="info-tt" data-tooltip-content={z3Total + " Z³, " + daiTotal + " DAI"}>
                            {treasuryTotal ? <Typography component={'span'}>${treasuryTotal} <Info /></Typography> : '---'}
                            <Tooltip id="info-tt"></Tooltip>
                        </div>
                    </div>
                </div>

                <div className="connect-container">
                    <ConnectWallet switchToActiveChain={true} className="connect-btn" theme="dark" dropdownPosition={{ side: 'bottom', align: 'center'}} />
                </div>
            </div>
        </div>
    )
};

export default TaskBar;