import { useAddress, useSigner, useChainId } from "@thirdweb-dev/react";
import { useState, useEffect, Fragment } from 'react';
import { getAvailableNetworks } from '../../utils';
import { ethers } from "ethers";
import { useSnackbar } from "notistack";
import { 
    Container, 
    Paper, 
    makeStyles, 
    Typography, 
    Grid, 
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress,
    DialogActions,
    Box,
    TextField as CustomInput
} from "@material-ui/core";
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toWei, toPercentageDelimiter } from '../../utils';
import './index.css';
import library from "../../Constants/contracts.js";
import * as chains from "../../Constants/chains";

let signer = null;
let connectedAddress = null;
let chainId = null;

export default function Dashboard(props) {
    const styles = (theme) => ({
        paperContainer: {
            padding: theme.spacing(5),
            paddingBottom: theme.spacing(3),
            boxShadow: "none!important",
            textAlign: "center!important"
        },
        fullWidth: {
            width: "100%",
        },
        tokenContainer: {
            marginTop: 20,
            marginBottom: 20,
            paddingBottom: 20,
            borderBottom: "1px solid #464646"
        },
        title: {
            textAlign: "left"
        },
        modalContent: {
            padding: "20px"
        },
        flex: {
            alignItems: "center",
            justifyContent: "space-evenly",
            display: "flex"
        },
        tokenBox: {

        },
        tokenBoxContainer: {
            border: "1px solid #858585",
            padding: "25px 5px!important",
            borderRadius: "15px"
        },
        statistic: {
            marginBottom: "10px",
            color: "#fff!important",
            fontWeight: "800!important"
        },
        elemContainer: {
            paddingTop: "15px",
            paddingBottom: "25px"
        },
        tokenTitle: {
            marginTop: "25px",
            marginBottom: "25px",
            paddingLeft: "15px"
        },
        grey: {
            backgroundColor: "#6b6b6b!important"
        }
    });

    const { enqueueSnackbar } = useSnackbar();
    const useStyles = makeStyles(styles);
    const classes = useStyles();
    const [isChecking, setIsChecking] = useState(false);
    const [isValidToken, setIsValidToken] = useState(false);
    const [tokenAddress, setTokenAddress] = useState('');
    const [contracts, setContracts] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [isExecuting, setIsExecuting] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const [commandTitle, setCommandTitle] = useState('');
    const [commandInfo, setCommandInfo] = useState('');
    const [commandActions, setCommandActions] = useState('');
    const [initialValues, setInitialValues] = useState(null);
    const [validationSchema, setValidationSchema] = useState(null);

    const checkToken = async() => {
        if (connectedAddress) {
            if (/^0x[a-fA-F0-9]{40}$/.test(tokenAddress)) {
                setIsChecking(true);

                try {
                    const tokenContract = new ethers.Contract(tokenAddress, JSON.parse(contracts[0].abi), signer);

                    if (tokenContract) {
                        const owner = await tokenContract.owner();

                        if (owner != connectedAddress) {
                            notify(0, "You are not the owner of this token");
                        }
                        else {
                            setIsValidToken(true);
                        }
                    }
                    else {
                        setIsValidToken(false);
                    }
                }
                catch(err) {
                    notify(0, "Unable to read contract. Please double check the address is correct");
                    setIsValidToken(false);
                    console.log(err);
                }

                setIsChecking(false);
            }
            else {
                notify(0, "Invalid ETH Address");
                setIsValidToken(false);
            }
        }
        else {
            notify(0, "Please connect your wallet");
        }
    };
    
    const interact = (flag) => {
        setSelectedIndex(flag);

        switch(flag) {
            case 0: // airdropToWalllets
                setCommandTitle("Airdrop tokens to wallets");
                setCommandInfo("Enter a comma separated line of wallet addresses and the number of tokens you want them to receive");
                setInitialValues({
                    adAddresses: '',
                    adValues: ''
                });
                setValidationSchema({
                    adAddresses: Yup.string()
                    .matches(/^0x[a-fA-F0-9]{40}(,0x[a-fA-F0-9]{40})*$/, "The addresses are not in a valid format")
                    .test('addressTotalValid', 'The wallet addresses count is not the same as the token allocations count', (val, schema) => {
                      let wallets = [];
                      let values = [];

                      try {
                        wallets = schema.parent.adAddresses.split(",");
                        values = schema.parent.adValues.split(",");
                        return wallets.length === values.length;
                      } 
                      catch(err) {
                        if (wallets.length != values.length) {
                          return false;
                        }
                        else {
                          return true;
                        }
                      }
                    }),
                  adValues: Yup.string()
                    .matches(/^\d+(,\d+)*$/, "The token allocations are not in a valid format")
                    .test('valueTotalValid', 'The token allocation count does not match the wallet address count', (val, schema) => {
                      let wallets = [];
                      let values = [];

                      try {
                        values = schema.parent.adValues.split(",");
                        wallets = schema.parent.adAddresses.split(",");
                        return wallets.length === values.length;
                      } 
                      catch(err) {
                        if (wallets.length != values.length) {
                          return false;
                        }
                        else {
                          return true;
                        }
                      }
                    })
                });
                setCommandActions(
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12}>
                            <Typography variant="h7" className={classes.airdropLb} spacing={2}>
                                Enter wallet addresses, separated by commas
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} placeholder="0x...,0x...,0x..." fullWidth variant="outlined" name="adAddresses"  label="Wallet Addresses" multiline rows={2} maxRows={4}/>
                        </Grid>

                        <Grid item md={12} xs={12}>
                            <Typography variant="h7" className={classes.airdropLb} spacing={2}>
                                Enter token allocation for each wallet, separated by commas
                            </Typography>
                        </Grid>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} placeholder="25,55,105" fullWidth variant="outlined" name="adValues" label="Token Amounts" multiline rows={2} maxRows={4}/>
                        </Grid>
                    </Grid>
                );
            break;
            case 1: // Approve
                setCommandTitle("Approve wallet");
                setCommandInfo("Enter the wallet and amount of tokens you want to approve for buying/selling/transferring");
                setInitialValues({
                    spenderAddress: '',
                    spenderValue: ''
                });
                setValidationSchema({
                    spenderAddress: Yup.string()
                    .matches(/^0x[a-fA-F0-9]{40}(,0x[a-fA-F0-9]{40})*$/, "The addresses are not in a valid format"),
                    spenderValue: Yup.number()
                    .required('Spender amount is required')
                    .min(0, 'Must be greater than 0')
                });

                setCommandActions(
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} placeholder="0x..." fullWidth variant="outlined" name="spenderAddress" label="Wallet Address"/>
                        </Grid>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} fullWidth variant="outlined" name="spenderValue" label="Number of tokens to approve"/>
                        </Grid>
                    </Grid>
                );
            break;
            case 2: // Decrease Allowance
                setCommandTitle("Decrease allowance");
                setCommandInfo("Decrease the token allowance for selected wallet address. This can help limit the Dev Team/Whales trades");
                setInitialValues({
                    spenderAddress: '',
                    spenderValue: ''
                });
                setValidationSchema({
                    spenderAddress: Yup.string()
                    .matches(/^0x[a-fA-F0-9]{40}(,0x[a-fA-F0-9]{40})*$/, "The addresses are not in a valid format"),
                    spenderValue: Yup.number()
                    .required('Spender amount is required')

                });

                setCommandActions(
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} placeholder="0x..." fullWidth variant="outlined" name="spenderAddress" label="Wallet Address"/>
                        </Grid>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} fullWidth variant="outlined" name="spenderValue" label="The number of tokens to decrease"/>
                        </Grid>
                    </Grid>
                );
            break;
            case 3: // Increase Allowance
                setCommandTitle("Increase allowance");
                setCommandInfo("Increase the token allowance for selected wallet address");
                setInitialValues({
                    spenderAddress: '',
                    spenderValue: ''
                });
                setValidationSchema({
                    spenderAddress: Yup.string()
                    .matches(/^0x[a-fA-F0-9]{40}(,0x[a-fA-F0-9]{40})*$/, "The addresses are not in a valid format"),
                    spenderValue: Yup.number()
                    .required('Spender amount is required')
                    .min(0, 'Must be greater than 0')
                });

                setCommandActions(
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} placeholder="0x..." fullWidth variant="outlined" name="spenderAddress" label="Wallet Address"/>
                        </Grid>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} fullWidth variant="outlined" name="spenderValue" label="The number of tokens to increase"/>
                        </Grid>
                    </Grid>
                );
            break;
            case 4: // Remove Limits
                setCommandTitle("Remove Limits");
                setCommandInfo("This function will remove all transaction and holding limits on your token. Please note this does NOT remove any tax on your token.");
                setInitialValues({});
                setValidationSchema({});
                setCommandActions('');
            break;
            case 5: // Renounce Ownership
                setCommandTitle("Renounce Ownership");
                setCommandInfo("This function will renounce ownership of your tokens smart contract. Please note this is an IRREVERSIBLE function. Once this function has been executed, you will no longer be able to make any adjustments to the smart contract.");
                setInitialValues({});
                setValidationSchema({});
                setCommandActions('');
            break;
            case 6: // Update Buy Tax
                setCommandTitle("Update Buy Tax");
                setCommandInfo("This function will update your tokens buy tax percentage. Please note you can NOT raise the buy tax above the default set at launch.");
                setInitialValues({
                    spenderValue: ''
                });
                setValidationSchema({
                    spenderValue: Yup.number()
                    .required('Percentage amount is required')
                    .min(0, 'Tax Percentage must be 0% or more')
                    .max(30, 'Tax Percentage must be 30% or less')
                    .test("is-decimal", "Must be 2 decimals places or less", (val, schema) => {
                      return val !== undefined ? /^\d+(\.\d{2})?$/.test(val) : false;
                    }),
                });

                setCommandActions(
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} fullWidth variant="outlined" name="spenderValue" label="The tax amount in percentage"/>
                        </Grid>
                    </Grid>
                );
            break;
            case 7: // Update Buy Tax
                setCommandTitle("Update Sell Tax");
                setCommandInfo("This function will update your tokens sell tax percentage. Please note you can NOT raise the sell tax above the default set at launch.");
                setInitialValues({
                    spenderValue: ''
                });
                setValidationSchema({
                    spenderValue: Yup.number()
                    .required('Percentage amount is required')
                    .min(0, 'Tax Percentage must be 0% or more')
                    .max(30, 'Tax Percentage must be 30% or less')
                    .test("is-decimal", "Must be 2 decimals places or less", (val, schema) => {
                      return val !== undefined ? /^\d+(\.\d{2})?$/.test(val) : false;
                    }),
                });

                setCommandActions(
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} fullWidth variant="outlined" name="spenderValue" label="The tax amount in percentage"/>
                        </Grid>
                    </Grid>
                );
            break;
            case 8: // Transfer Ownership
                setCommandTitle("Transfer Ownership");
                setCommandInfo("This function will transfer ownership of your token contract. Please note that this function is IRREVERSIBLE once executed, please take extra care when transferring contract ownership.");
                setInitialValues({
                    spenderAddress: '',
                });
                setValidationSchema({
                    spenderAddress: Yup.string()
                    .matches(/^0x[a-fA-F0-9]{40}(,0x[a-fA-F0-9]{40})*$/, "The addresses are not in a valid format"),
                });

                setCommandActions(
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} placeholder="0x..." fullWidth variant="outlined" name="spenderAddress" label="Wallet Address"/>
                        </Grid>
                    </Grid>
                );
            break;
            case 9: // Update Project Wallet
                setCommandTitle("Update Project Wallet");
                setCommandInfo("This function will update/change the project wallet (tax wallet) within the contract.");
                setInitialValues({
                    spenderAddress: '',
                });
                setValidationSchema({
                    spenderAddress: Yup.string()
                    .matches(/^0x[a-fA-F0-9]{40}(,0x[a-fA-F0-9]{40})*$/, "The addresses are not in a valid format"),
                });

                setCommandActions(
                    <Grid container spacing={2}>
                        <Grid item md={12} xs={12} spacing={2}>
                            <Field component={TextField} placeholder="0x..." fullWidth variant="outlined" name="spenderAddress" label="Wallet Address"/>
                        </Grid>
                    </Grid>
                );
            break;
            default:
                setModalOpen(false);
            break;
        }

        setModalOpen(true);
    };

    async function performAction(values) {
        if (connectedAddress) {
            try {
                setIsExecuting(true);

                const tokenContract = new ethers.Contract(tokenAddress, JSON.parse(contracts[0].abi), signer);
                console.log(tokenContract);

                let transaction = null;

                switch(selectedIndex) {
                    case 0:
                        let adAddresses = [];
                        let adValues = [];

                        if (values.adAddresses) {
                            adAddresses = values.adAddresses.split(",");
                        }

                        if (values.adValues) {
                            let tmpValues = values.adValues.split(",");
                        
                            for (let i = 0; i < tmpValues.length; i++) {
                                if (tmpValues[i] >= 0) {
                                    let valueInWei = toWei(tmpValues[i].toString());
                                    adValues.push(valueInWei.toString());
                                }
                            }
                        }

                        transaction = await tokenContract.airdropToWallets(adAddresses, adValues);
                    break;
                    case 1:
                        transaction = await tokenContract.approve(values.spenderAddress, toWei(values.spenderValue).toString());
                    break;
                    case 2:
                        transaction = await tokenContract.decreaseAllowance(values.spenderAddress, toWei(values.spenderValue).toString());
                    break;
                    case 3:
                        transaction = await tokenContract.increaseAllowance(values.spenderAddress, toWei(values.spenderValue).toString());
                    break;
                    case 4:
                        transaction = await tokenContract.removeLimits();
                    break;
                    case 5:
                        transaction = await tokenContract.renounceOwnership();
                    break;
                    case 6:
                        transaction = await tokenContract.updateBuyTax(toPercentageDelimiter(values.spenderValue).toString());
                    break;
                    case 7:
                        transaction = await tokenContract.updateSellTax(toPercentageDelimiter(values.spenderValue).toString());
                    break;
                    case 8:
                        transaction = await tokenContract.transferOwnership(values.spenderAddress);
                    break;
                    case 9:
                        transaction = await tokenContract.updateProjectAddress(values.spenderAddress);
                    break;
                }

                if (transaction) {
                    await transaction.wait().then(async(result) => {
                        console.log(result);
                        notify(1, commandTitle + " action was successful");
                        setIsExecuting(false);
                        setModalOpen(false);
                    });
                }
                else {
                    setIsExecuting(false);
                    notify(0, "Could not find action selected");
                }
            }
            catch(err) {
                setIsExecuting(false);
                setModalOpen(false);
                console.log(err);

                if (err.toString().indexOf("user rejected transaction") === -1) {
                    switch(selectedIndex) {
                        case 2:
                            notify(0, "There was an error with the transaction. Make sure their allowance is > 0 to use this function");
                        break;
                        case 5:
                        case 6:
                            notify(0, "There was an error with the transaction. Keep tax at or below 5% and/or current tax");
                        break;
                        default:
                            notify(0, "There was an error with the transaction");
                        break;
                    }
                }
            }
        }
        else {
            notify(0, "Please connect your wallet");
        }
    }

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") 
          return;
        setModalOpen(false);
    };

    const notify = (flag, msg, delay = 10000) => {
        let type = "error";
  
        if (flag === 1) {
            type = "success";
        }
  
        enqueueSnackbar(msg, { variant: type, autoHideDuration: delay, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
        }});
    };

    const initialise = () => {
        if (chainId && signer.provider) {
            if (chains.networks.includes(chainId)) {
                let tmpContracts = library.get(chainId);

                if (tmpContracts) {
                    setContracts(tmpContracts);

                    if (/^0x[a-fA-F0-9]{40}$/.test(tokenAddress)) {
                        checkToken();
                    }
                }
            }
            else {
                notify(0, "Please connect to the following networks: " + getAvailableNetworks(chains.networks));
            }
        }
    };

    useEffect(() => {
        var t = setInterval(() => {
            if (signer) {
              if (signer.provider) {
                clearInterval(t);
                initialise();
              }
              else{
                setContracts(null);
              }
            }
            else {
                setContracts(null);
            }
          }, 500);
    }, [signer]);

    signer = useSigner();
    connectedAddress = useAddress();
    chainId = useChainId();

    return(
    <>
        <Container maxWidth="md" className={contracts ? 'lockerContainer' : 'lockerContainer no-wallet'}>
            <Fragment>
                <Paper className={classes.paperContainer}>
                    <Typography variant="h4" className={classes.title}>
                        Manage Your Token
                    </Typography>
                    <Grid container spacing={2} className={classes.tokenContainer}>
                        <Grid item md={9} xs={12} >
                            <CustomInput fullWidth value={tokenAddress ? tokenAddress : '' } onChange={(e) => setTokenAddress(e.target.value)} variant="outlined" name="tokenAddress" type="string" label="Enter Token Address" placeholder="0x..." />
                        </Grid>
                        <Grid item md={3} xs={12} className={classes.flex}>
                            <Button onClick={() => checkToken()} startIcon={isChecking ? <CircularProgress style={{'color': '#fff'}} size="1rem" /> : null} disabled={isChecking} variant="contained" color="primary">{isChecking ? "Checking..." : "Manage Token"}</Button>
                        </Grid>
                    </Grid>
                    {isValidToken ? 
                        <Grid container>
                            <Grid container spacing={2}>
                                <Typography variant="h6" className={classes.tokenTitle}>
                                    Main Functions
                                </Typography>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={3} className={classes.tokenBox}>
                                    <Box className={classes.tokenBoxContainer}>
                                        <Typography variant="h6" className={classes.statistic}>Remove Limits</Typography>
                                        <Button onClick={() => interact(4)} variant="contained" color="primary">Configure</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={3} className={classes.tokenBox}>
                                    <Box className={classes.tokenBoxContainer}>
                                        <Typography variant="h6" className={classes.statistic}>Update Buy Tax</Typography>
                                        <Button onClick={() => interact(6)} variant="contained" color="primary">Configure</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={3} className={classes.tokenBox}>
                                    <Box className={classes.tokenBoxContainer}>
                                        <Typography variant="h6" className={classes.statistic}>Update Sell Tax</Typography>
                                        <Button onClick={() => interact(7)} variant="contained" color="primary">Configure</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={3} className={classes.tokenBox}>
                                    <Box className={classes.tokenBoxContainer}>
                                        <Typography variant="h6" className={classes.statistic}>Transfer Ownership</Typography>
                                        <Button onClick={() => interact(8)} variant="contained" color="primary">Configure</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={3} className={classes.tokenBox}>
                                    <Box className={classes.tokenBoxContainer}>
                                        <Typography variant="h6" className={classes.statistic}>Update Project Wallet</Typography>
                                        <Button onClick={() => interact(9)} variant="contained" color="primary">Configure</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={3} className={classes.tokenBox}>
                                    <Box className={classes.tokenBoxContainer}>
                                        <Typography variant="h6" className={classes.statistic}>Renounce Ownership</Typography>
                                        <Button onClick={() => interact(5)} variant="contained" color="primary">Configure</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            {/*
                            <Grid container spacing={2}>
                                <Typography variant="h6" className={classes.tokenTitle}>
                                    Other Functions
                                </Typography>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={6} md={3} className={classes.tokenBox}>
                                    <Box className={classes.tokenBoxContainer}>
                                        <Typography variant="h6" className={classes.statistic}>Airdrop Wallets</Typography>
                                        <Button onClick={() => interact(0)} variant="contained" color="primary" className={classes.grey}>Configure</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={3} className={classes.tokenBox}>
                                    <Box className={classes.tokenBoxContainer}>
                                        <Typography variant="h6" className={classes.statistic}>Approve Wallet</Typography>
                                        <Button onClick={() => interact(1)} variant="contained" color="primary" className={classes.grey}>Configure</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={3} className={classes.tokenBox}>
                                    <Box className={classes.tokenBoxContainer}>
                                        <Typography variant="h6" className={classes.statistic}>Decrease Allowance</Typography>
                                        <Button onClick={() => interact(2)} variant="contained" color="primary" className={classes.grey}>Configure</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={6} md={3} className={classes.tokenBox}>
                                    <Box className={classes.tokenBoxContainer}>
                                        <Typography variant="h6" className={classes.statistic}>Increase Allowance</Typography>
                                        <Button onClick={() => interact(3)} variant="contained" color="primary" className={classes.grey}>Configure</Button>
                                    </Box>
                                </Grid>
                            </Grid>*/}
                        </Grid>
                    : ''}
                    <Dialog open={modalOpen} onClose={handleClose} className="result-dialog">
                        <div className={classes.modal}>
                            <DialogTitle>{commandTitle}</DialogTitle>
                            <DialogContent className={classes.modalContent}>
                                <DialogContentText>
                                    {commandInfo}
                                </DialogContentText>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={Yup.object().shape(validationSchema)}
                                    onSubmit={values => {performAction(values)}}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <div className={classes.elemContainer}>
                                                {commandActions}
                                            </div>
                                            <DialogActions>
                                                <Button variant="contained" color="primary" className='cancelButton' onClick={handleClose}>Cancel</Button>
                                                <Button type="submit" variant="contained" color="primary" startIcon={isExecuting ? <CircularProgress style={{'color': '#fff'}} size="1rem" /> : null} disabled={isExecuting}  >{isExecuting ? "Executing..." : "Execute"}</Button>
                                            </DialogActions>
                                        </Form>
                                    )}
                                </Formik>
                            </DialogContent>
                        </div>
                    </Dialog>
                </Paper>
            </Fragment>
        </Container>
    </>
    );
}