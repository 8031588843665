import React, { useEffect, useState } from "react";
import { Container, Grid, IconButton, makeStyles, Paper, Typography, InputAdornment, FormHelperText, FormControl, OutlinedInput } from "@material-ui/core";
import { getAmountOut, getBalanceAndSymbol, getReserves, swapZevTokens, getWeth, doesTokenExistBool, getFactory, getRouter, getBlockNumber, getBlock } from "../../etherFunctions";
import { useAddress, useChainId, useSigner, useSwitchChain } from "@thirdweb-dev/react";
import { getNonce, getEtherErrorMessage, fromWei, convertCurrency, getAvailableNetworks, numberExponentToLarge } from '../../utils';
import { useSnackbar } from "notistack";
import { ethers } from "ethers";
import SwapVerticalCircleIcon from "@material-ui/icons/SwapVerticalCircle";
import LoopIcon from "@material-ui/icons/Loop";
import CoinField from "./CoinField";
import CoinDialog from "./CoinDialog";
import LoadingButton from "../../Components/LoadingButton";
import WrongNetwork from "../../Components/WrongNetwork";
import ReCAPTCHA from "react-google-recaptcha";
import COINS from "../../Constants/coins";
import library from "../../Constants/contracts";
import * as chains from "../../Constants/chains";
import './index.css';

var CryptoJS = require("crypto-js");

const styles = (theme) => ({
  paperContainer: {
    borderRadius: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingTop: "15px"
  },
  switchButton: {
    zIndex: 1,
    padding: theme.spacing(0),
    color: '#fff',
    marginTop: "10px"
  },
  fullWidth: {
    width: "100%",
  },
  halfWidth: {
    width: 100,
    height: 0
  },
  title: {
    textAlign: "center",
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  subtitle: {
    textAlign: "center!important",
    marginBottom: "15px!important",
    fontSize: "15px!important"
  },
  hr: {
    width: "100%",
    borderColor: "#000"
  },
  balance: {
    padding: theme.spacing(1),
    overflow: "wrap",
    textAlign: "left",
    fontSize: "13px",
    marginTop: "-45px",
    marginLeft: "5px"
  },
  footer: {
    marginTop: "285px",
  },
  customSize: {
    minHeight: "100px"
  },
  paddedContainer: {
    paddingTop: "0px"
  },
  maxHyper: {
    color: "#36c",
    textDecoration: "none",
    cursor: "pointer",
    "&:hover": {
      color: "#2854ab",
    },
    marginLeft: 5,
  },
  titleContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    flexDirection: "row",
    alignItems: "center"
  },
  disabled: {
    cursor: "not-allowed",
    opacity: "0.5"
  },
  balanceContainer: {
    display: "flex",
    justifyContent: "space-between"
  }
});

const useStyles = makeStyles(styles);

let ZentinelContract = null;
let zevContract = null;
let _signer = null;
let _connectedAddress = null;
let _chainId = null;

function Swap(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const switchChain = useSwitchChain();

  const [dialog1Open, setDialog1Open] = React.useState(false);
  const [dialog2Open, setDialog2Open] = React.useState(false);
  const [wrongNetworkOpen, setwrongNetworkOpen] = React.useState(false);
  const [slippage, setSlippage] = React.useState(49);

  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [account, setAccount] = useState(null);
  const [coins, setCoins] = useState([]);
  const [chainID, setChainId] = useState(null);
  const [router, setRouter] = useState(null);
  const [factory, setFactory] = useState(null);
  const [weth, setWETH] = useState(null);
  const [outputCurrency, setOutputCurrency] = useState(null);
  const [inputCurrency, setInputCurrency] = useState(null);
  const [initialised, setInitialised] = useState(false);
  const [zevTokens, setZevTokens] = useState([]);
  const [lastInput, setLastInput] = useState(1);
  const [maxBuy, setMaxBuy] = useState(0);
  const [isZentinel, setIsZentinel] = useState(false);
  const [disclaimerMessage, setDisclaimerMessage] = useState('');
  const [tokenSummaryOutput, setTokenSummaryOutput] = useState('');
  const [zevContracts, setZevContracts] = useState(null);

  // Stores data about their respective coin
  const [coin1, setCoin1] = React.useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
    decimals: undefined,
    name: undefined
  });

  const [coin2, setCoin2] = React.useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
    decimals: undefined,
    name: undefined,
    totalSupply: undefined,
    maxBuy: undefined,
    buyTax: undefined,
    sellTax: undefined,
    maxWallet: undefined,
    maxTransactions: undefined
  });

  const [reserves, setReserves] = React.useState(["0.0", "0.0"]);
  const [field1Value, setField1Value] = React.useState("");
  const [field2Value, setField2Value] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const reRef = React.useRef();
  const [isLaunched, setIsLaunched] = React.useState(false);

  const switchFields = () => {
    setCoin1(coin2);
    setCoin2(coin1);
    setField1Value(field2Value);
    setReserves(reserves.reverse());
  };

  const field1HandleChange = {
    field1: (e) => {
      setLastInput(1);
      setField1Value(e.target.value.trim());
    },
  };

  const field2HandleChange = {
    field2: (e) => {
      setLastInput(2);
      setField2Value(e.target.value.trim());
    },
  };

  const setCoinOneMax = async() => {
    setLastInput(1);
    setField1Value(coin1.balance);
  };

  const setCoinTwoMax = async() => {
    setLastInput(2);
    setField2Value(maxBuy);
  };

  const formatBalance = (balance, decimals = 8) => {
    if (balance)
      return parseFloat(balance).toFixed(8);
    else return "0.0";
  };

  const isButtonEnabled = () => {
    const parsedInput1 = field1Value;
    const parsedInput2 = field2Value;
    const parsedBalance = coin1.balance;

    return (
      coin1.address &&
      coin2.address &&
      !isNaN(parsedInput1) &&
      !isNaN(parsedInput2) &&
      !isNaN(parsedBalance) &&
      0 <= parsedInput1 &&
      parsedInput1 <= parsedBalance &&
      isLaunched
    );
  };

  const onToken1Selected = (address) => {
    setDialog1Open(false);

    if (address === coin2.address) {
      switchFields();
    }
    else if (address) {
      getBalanceAndSymbol(account, address, provider, signer, weth.address, coins).then((data) => {
        if (data) {
          setCoin1({
            address: address,
            symbol: data.symbol,
            balance: data.balance,
            decimals: data.decimals,
            name: data.name
          });

          setIsLaunched(true);
          setIsZentinel(false);
          setMaxBuy(data.balance);
          setDisclaimerMessage("");
          setTokenSummaryOutput("");
        }
      });
    }
  };

  const onToken2Selected = (address) => {
    setDialog2Open(false);

    if (address === coin1.address) {
      switchFields();
    }
    else if (address) {
      getBalanceAndSymbol(account, address, provider, signer, weth.address, coins).then(async(data) => {
        if (data) {
          if (zevTokens.indexOf(address) > -1) {
            getBalanceAndSymbol(account, weth.address, provider, signer, weth.address, coins).then(async (data2) => {
              if (data2) {
                setCoin1({
                  address: weth.address,
                  symbol: data2.symbol,
                  balance: data2.balance,
                  decimals: data2.decimals,
                  name: data2.name
                });
              }
            });

            try {
              zevContract = new ethers.Contract(address, JSON.parse(zevContracts[0].abi), signer);
              
              const tmpTotalBuyTax = await zevContract.buyTax();
              const tmpTotalSellTax = await zevContract.sellTax();
              const tmpMaxWallet = await zevContract.maxWallet();
              const tmpTotalSupply = await zevContract.totalSupply();
              const tmpMaxTransaction = await zevContract.maxTransaction();
              const tmpLaunchTime = await zevContract.launchTime_();
              const tmpMaxTransactions = await zevContract.maxTransaction();

              const totalBuyTax = parseInt(tmpTotalBuyTax) / 100;
              const totalSellTax = parseInt(tmpTotalSellTax) / 100;
              const maxWallet = parseFloat(fromWei(tmpMaxWallet, data.decimals));
              const maxBuy = parseFloat(fromWei(tmpMaxTransaction, data.decimals));
              const totalSupply = parseFloat(fromWei(tmpTotalSupply).toString(), data.decimals);
              const launchTime = parseInt(tmpLaunchTime.toString());
              const maxTransactions = parseFloat(fromWei(tmpMaxTransactions, data.decimals));
              
              setCoin2({
                address: address,
                symbol: data.symbol,
                balance: data.balance,
                decimals: data.decimals,
                name: data.name,
                totalSupply: totalSupply,
                maxBuy: maxBuy,
                buyTax: totalBuyTax,
                sellTax: totalSellTax,
                maxWallet: maxWallet,
                verificationDuration: 1200,
                launchTime:launchTime,
                maxTransactions:maxTransactions
              });

              checkLaunch({
                address: address,
                symbol: data.symbol,
                balance: data.balance,
                decimals: data.decimals,
                name: data.name,
                totalSupply: totalSupply,
                maxBuy: maxBuy,
                buyTax: totalBuyTax,
                sellTax: totalSellTax,
                maxWallet: maxWallet,
                verificationDuration: 1200,
                launchTime:launchTime,
                maxTransactions:maxTransactions
              });
            }
            catch(err) {
              notify(0, "Error loading Zev Token. Please refresh the page and try again");
              console.log(err);
            }
          }
          else {
            setCoin2({
              address: address,
              symbol: data.symbol,
              balance: data.balance,
              decimals: data.decimals,
              name: data.name
            });
            
            setIsLaunched(true);
            setIsZentinel(false);
            setMaxBuy(data.balance);
            setDisclaimerMessage("");
            setTokenSummaryOutput("");
          }
        }
      });
    }
  };

  const validateToken = async(token) => {
    var response = {
      'msg' : 'No response from Server',
      'status' : false
    };

    let recaptchaURL = "/scripts/verify-recaptcha.php";

    if (process.env.REACT_APP_NETWORK === "localhost") {
      recaptchaURL = "https://zev2publictesting.gemclub.cc/scripts/verify-recaptcha.php";
    }

    await fetch(recaptchaURL, { 
      method: "POST",
      body: JSON.stringify({
        response: token,
        api_key: process.env.REACT_APP_RECAPTCHA_API_KEY
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    })
    .then((response) => response.json())
    .then((responseData) => {
      response = responseData;
    })
    .catch(error => console.warn(error));

    return response;
  };

  const notify = (flag, msg, delay = 10000) => {
    let type = "error";

    if (flag === 1) {
      type = "success";
    }
    else if (flag === 2) {
      type = "info"
    }

    enqueueSnackbar(msg, { variant: type, autoHideDuration: delay, anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
    }});
  };

  const swap = async() => {
    if (!isLaunched) {
      notify(0, "Token is not launched yet");
      return;
    }

    const token = await reRef.current.executeAsync();
    reRef.current.reset();

    const response = await validateToken(token);

    if (!response.status) {
      notify(0, "Recapatcha Failed");
    }
    else {
      setLoading(true);

      if (isZentinel) { // Zev3 token
        try {
          zevContract = new ethers.Contract(coin2.address, JSON.parse(zevContracts[0].abi), _signer);
          const tmpLT = await zevContract.launchTime_();
          
          if (!tmpLT) {
            setLoading(false);
            notify(0, "Could not get launch time from contract");
            return;
          }

          const launchTime = parseInt(tmpLT.toString());
          const blockNumber = await getBlockNumber(provider);
          const block = await getBlock(provider, blockNumber);
          const timestamp = block.timestamp;

          if (launchTime <= timestamp) {
            const nonce = getNonce();
            const decoded = CryptoJS.AES.decrypt(process.env.REACT_APP_NETWORK === "production" ? process.env.REACT_APP_VK_Main : process.env.REACT_APP_VK_Test, process.env.REACT_APP_CJSK);
            const msg = decoded.toString(CryptoJS.enc.Utf8);
            const wallet = new ethers.Wallet(msg);
            const payload = ethers.utils.solidityKeccak256(["address", "address", "uint256"], [coin2.address, _connectedAddress, nonce]);
            const flatSig = await wallet.signMessage(ethers.utils.arrayify(payload));
            const sig = ethers.utils.splitSignature(flatSig);

            swapZevTokens(
              coin1.address,
              coin2.address,
              field1Value,
              router,
              signer,
              sig,
              ZentinelContract,
              slippage / 100,
              nonce
            ).then((res) => {
              refreshCoins();
              setLoading(false);
              setField1Value("");
              setLastInput(1);
              notify(1, "Transaction Successful");
            })
            .catch((e) => {
              setLoading(false);
              notify(0, getEtherErrorMessage(e));
            });
          }
          else {
            notify(0, "Token not launched yet");
            setLoading(false);
          }
        }
        catch(err) {
          console.log(err);
          setLoading(false);
        }
      }
      else {
        setLoading(false);
        notify(2, "Zentinel Swap is for the purchase of Zev³ Tokens only");

        /*swapTokens(
          coin1.address,
          coin2.address,
          field1Value,
          router,
          account,
          signer,
          slippage / 100
        )
        .then(() => {
          refreshCoins();
          setLoading(false);
          setField1Value("");
          setLastInput(1);
          notify(1, "Transaction Successful");
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
          notify(0, getEtherErrorMessage(e));
        });
        */
      }
    }
  };

  const loadNetwork = async() => {
    if (_chainId && _signer.provider) {
      if (chains.networks.includes(_chainId)) {
        let tmpContracts = library.get(_chainId);

        if (tmpContracts) {
          setZevContracts(tmpContracts);
          ZentinelContract = new ethers.Contract(tmpContracts[9].address, JSON.parse(tmpContracts[9].abi), _signer);

          let tmpProv = new ethers.providers.Web3Provider(window.ethereum);

          setProvider(tmpProv);
          setSigner(_signer);
          setAccount(_connectedAddress);
          setChainId(_chainId);
    
          const queryParams = new URLSearchParams(window.location.search);
          const outputCurrency = queryParams.get("outputCurrency");
          const inputCurrency = queryParams.get("inputCurrency");
          const slippage = queryParams.get("slippage");

          if (outputCurrency) {
            if (doesTokenExistBool(outputCurrency, _signer)) {
                setOutputCurrency(outputCurrency);
            }
          }
    
          if (inputCurrency) {
            if (doesTokenExistBool(inputCurrency, _signer)) {
                setInputCurrency(inputCurrency);
            }
          }
    
          if (parseFloat(slippage) >= 0 && parseFloat(slippage) <= 100) {
            setSlippage(slippage);
          }
    
          if (chains.networks.includes(_chainId)) {
              const router = await getRouter(chains.routerAddress.get(_chainId), _signer);
              setRouter(router);
    
              let coins = COINS.get(_chainId);

              const wethAddress = await router.WETH();
              let weth = getWeth(wethAddress, _signer);
              coins[0].address = wethAddress;
              setWETH(weth);
              setCoins(coins);
    
              const factory_address = await router.factory();
              let factory = getFactory(factory_address, _signer);
              setFactory(factory);

              let tmpZevTokens = await getAllTokens(_chainId);
              let zevTokens = [];
        
              if (tmpZevTokens.data.length > 0) {
                for (let i = 0; i < tmpZevTokens.data.length; i++) {
                  zevTokens.push(tmpZevTokens.data[i].contractAddress);
                }
              }
              
              setZevTokens(zevTokens);
              setInitialised(true);
          }

          const chainIdParam = queryParams.get("chainId");

          if (parseInt(chainIdParam) !== parseInt(_chainId)) {
            switchChain(parseInt(chainIdParam)).then(function(res) {
              window.location.reload();
            });
          }
        }
        else {
          notify(0, "Please connect to the following networks: " + getAvailableNetworks(chains.networks));
        }
      }
      else {
        notify(0, "Please connect to the following networks: " + getAvailableNetworks(chains.networks));
      }
    }
  };

  const getAllTokens = async(chainId) => {
    var response = {
      'data' : [],
      'status' : false
    };

    let tokenURL = "/scripts/getTokens.php";

    if (process.env.REACT_APP_NETWORK === "localhost") {
        tokenURL = "https://zev2publictesting.gemclub.cc/scripts/getTokens.php";
    }
    
    await fetch(tokenURL, { 
      method: "POST",
      body: JSON.stringify({
        api_key: process.env.REACT_APP_RECAPTCHA_API_KEY,
        branch: process.env.REACT_APP_NETWORK === "production" ? "main" : "dev",
        chainId: chainId
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8'
      }
    })
    .then((response) => response.json())
    .then((responseData) => {
        if (responseData.status) {
            const data = JSON.parse(responseData.data);

            var formattedArr = [];

            data.forEach(function(token, t) {
                if (token[10].args) {
                    const params = token[10].args.params;

                    formattedArr.push({
                        'contractAddress' : token[1],
                        'token_name': params._name,
                        'token_symbol': params._symbol,
                        'token_decimals': token[4],
                        'token_supply': numberExponentToLarge(params._supply),
                        'buy_tax': params._buyTax,
                        'sell_tax': params._sellTax,
                        'max_transaction': params._maxTransaction,
                        'max_wallet': params._maxWallet,
                        'launch_time': params._launchTime
                    });
                }
            });

            response = {"status" : true, data: formattedArr};;
        }
        else {
            response = {"status" : true, data: []};
        }
    })
    .catch(error => console.warn(error));

    return response;
  };

  const checkLaunch = async(coin) => {
    if (!coin.launchTime) {
      return;
    }
    
    const blockNumber = await getBlockNumber(provider);
    const block = await getBlock(provider, blockNumber);
    const launchTime = coin.launchTime;
    const timestamp = block.timestamp;
    const verificationBlockToCheck = launchTime + coin.verificationDuration;
    const tmpTokensLeft = parseFloat(coin.maxWallet - coin.balance).toFixed(coin.decimals);
    const tokensLeft = tmpTokensLeft < 0 ? 0 : tmpTokensLeft;

    if (timestamp >= launchTime && timestamp < verificationBlockToCheck) {
      setIsZentinel(true);
      const maxBuy2 = Math.min(tokensLeft - (tokensLeft * 0.005), coin.maxTransactions - (coin.maxTransactions * 0.005));
      !isNaN(maxBuy2) ? setMaxBuy(maxBuy2.toFixed(coin.decimals)) : setMaxBuy(0);
      setIsLaunched(true);
    }
    else {
      setIsLaunched(false);
      setIsZentinel(false);
    }

    if (timestamp >= launchTime && timestamp < verificationBlockToCheck) {
      var timeleft = (verificationBlockToCheck - timestamp) * 1000;
      var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
      const timeElapsed = minutes >= 0 ? minutes + " minutes and " + seconds + " seconds" : "Calculating...";

      setDisclaimerMessage("Please Note: Zentinel Swap does NOT support selling of Zev³ Tokens for the initial " + (coin.verificationDuration / 60) + " minutes after launch. If you would like to sell within this time period please use Uniswap");
      setTokenSummaryOutput("<table><tr><thead><th align='left'>Token</th><th align='center'>Token Supply</th><th align='center'>Buyer's Protection End</th><th align='center'>Buy Tax</th><th align='center'>Sell Tax</th><th align='center'>Max Tokens Per Transaction</th><th align='center'>Max Wallet</th><th align='center'>Available For Purchase</th><th align='center'>Max Buy</th></thead></tr><tr class='noLine'><td data-label='Token' align='left'>"+ coin.name +" ("+ coin.symbol +")</td><td data-label='Token Supply' align='center'>"+ convertCurrency(coin.totalSupply) +"</td><td data-label='Buyers Protection' align='center'>"+ timeElapsed +"</td><td data-label='Buy Tax' align='center'>"+ coin.buyTax +"%</td><td data-label='Sell Tax' align='center'>"+ coin.sellTax +"%</td><td data-label='Max Per Transaction' align='center'>"+ coin.maxBuy +"</td><td data-label='Max Tokens Per Wallet' align='center'>"+ coin.maxWallet +"</td><td data-label='Available For Purchase' align='center'>"+ parseFloat(tokensLeft).toFixed(0) +"</td><td data-label='Max Buy' align='center'>"+ parseFloat(maxBuy).toFixed(0) +"</td></tr></table>");
    }
    else if (timestamp >= launchTime && timestamp > verificationBlockToCheck) {
      setTokenSummaryOutput("<table><tr><thead><th align='left'>Token</th><th align='center'>Token Supply</th><th align='center'>Launch Countdown</th><th align='center'>Buy Tax</th><th align='center'>Sell Tax</th><th align='center'>Max Tokens Per Transaction</th><th align='center'>Max Wallet</th></tr><th align='center'>Available For Purchase</th></thead><tr class='noLine'><td data-label='Token' align='left'>"+ coin.name +" ("+ coin.symbol +")</td><td data-label='Token Supply' align='center'>"+ convertCurrency(coin.totalSupply) +"</td><td data-label='Buyers Protection' align='center'>No longer under Zentinels guard</td><td data-label='Buy Tax' align='center'>"+ coin.buyTax +"%</td><td data-label='Sell Tax' align='center'>"+ coin.sellTax +"%</td><td data-label='Max Per Transaction' align='center'>"+ coin.maxBuy +"</td><td data-label='Max Tokens Per Wallet' align='center'>"+ coin.maxWallet +"</td><td data-label='Available For Purchase' align='center'>"+ parseFloat(tokensLeft).toFixed(0) +"</td></tr></table>");
    }
    else {
      const hourBeforeBlockToCheck = launchTime - 3600;
      let launchMsg = "Not Launched Yet";

      if (timestamp >= hourBeforeBlockToCheck) {
        var timeleft = (launchTime - timestamp) * 1000;
        var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
        launchMsg = minutes + " minutes and " + seconds + " seconds";
      }

      setDisclaimerMessage("");
      setTokenSummaryOutput("<table><tr><thead><th align='left'>Token</th><th align='center'>Token Supply</th><th align='center'>Launch Countdown</th><th align='center'>Buy Tax</th><th align='center'>Sell Tax</th><th align='center'>Max Tokens Per Transaction</th><th align='center'>Max Wallet</th></tr><th align='center'>Available For Purchase</th></thead><tr class='noLine'><td data-label='Token' align='left'>"+ coin.name +" ("+ coin.symbol +")</td><td data-label='Token Supply' align='center'>"+ convertCurrency(coin.totalSupply) +"</td><td data-label='Launch Countdown' align='center'>"+ launchMsg +"</td><td data-label='Buy Tax' align='center'>"+ coin.buyTax +"%</td><td data-label='Sell Tax' align='center'>"+ coin.sellTax +"%</td><td data-label='Max Per Transaction' align='center'>"+ coin.maxBuy +"</td><td data-label='Max Tokens Per Wallet' align='center'>"+ coin.maxWallet +"</td><td data-label='Available For Purchase' align='center'>"+ parseFloat(tokensLeft).toFixed(0) +"</td></tr></table>");
    }
  };

  const refreshCoins = () => {
    if (coin1.address && coin2.address && account) {
      getReserves(
        coin1.address,
        coin2.address,
        factory,
        signer,
        account
      ).then((data) => setReserves(data));
    }

    if (coin1.address && account &&!wrongNetworkOpen) {
      getBalanceAndSymbol(
        account,
        coin1.address,
        provider,
        signer,
        weth.address,
        coins
        ).then(
        async(data) => {
          setCoin1({
            ...coin1,
            balance: data.balance,
            decimals: data.decimals,
            name: data.name
          });
        }
      );
    }

    if (coin2.address && account &&!wrongNetworkOpen) {
      getBalanceAndSymbol(
        account,
        coin2.address,
        provider,
        signer,
        weth.address,
        coins
        ).then(
        async(data) => {
          setCoin2({
            ...coin2,
            balance: data.balance,
            decimals: data.decimals,
            name: data.name
          });

          if (zevTokens.indexOf(coin2.address) > -1) {
            checkLaunch(coin2);
          }
          else {
            setIsLaunched(true);
            setIsZentinel(false);
            setMaxBuy(coin2.balance);
            setDisclaimerMessage("");
            setTokenSummaryOutput("");
          }
        }
      );
    }
  };

  useEffect(() => {
    if (coin1.address && coin2.address) {
      getReserves(coin1.address, coin2.address, factory, signer, account).then(
        (data) => setReserves(data)
      );
    }
  }, [coin1.address, coin2.address, signer]);

  useEffect(() => {
    if (lastInput === 1) {
      if (isNaN(parseFloat(field1Value))) {
        setField2Value("");
      } 
      else if (parseFloat(field1Value) && coin1.address && coin2.address) {
        getAmountOut(coin1.address, coin2.address, field1Value, router, signer).then((amount) => setField2Value(amount.toFixed(coin1.decimals))).catch(e => {
          setField2Value("NA");
        });
      } 
      else {
        setField2Value("");
      }
    }
    else if (lastInput === 2) {
      if (isNaN(parseFloat(field2Value))) {
        setField1Value("");
      } 
      else if (parseFloat(field2Value) && coin1.address && coin2.address) {
        getAmountOut(coin2.address, coin1.address, field2Value, router, signer).then((amount) => setField1Value(amount.toFixed(coin2.decimals))).catch(e => {
          setField1Value("NA");
        });
      } 
      else {
        setField1Value("");
      }
    }
  }, [field1Value, field2Value, coin1.address, coin2.address]);

  useEffect(() => {
    const coinTimeout = setTimeout(() => {
      refreshCoins();
    }, 5000);

    return () => clearTimeout(coinTimeout);
  });

  useEffect(() => {
    if (initialised === true) {
      if (inputCurrency) {
        onToken1Selected(inputCurrency);
      }

      if (outputCurrency) {
        onToken2Selected(outputCurrency);
      }
    }
  }, [initialised]);

  useEffect(() => {
    var t = setInterval(() => {
      if (_signer) {
        if (_signer.provider) {
          loadNetwork();
          clearInterval(t);
        }
        else{
          setInitialised(false);
        }
      }
      else {
        setInitialised(false);
      }
    }, 500);
  }, [_signer]);

  _signer = useSigner();
  _connectedAddress = useAddress();
  _chainId = useChainId();

  return (
    <>
      {/* Dialog Windows */}
      <CoinDialog
        open={dialog1Open}
        onClose={onToken1Selected}
        coins={coins}
        props={signer}
      />
      <CoinDialog
        open={dialog2Open}
        onClose={onToken2Selected}
        coins={coins}
        signer={signer}
      />
      <WrongNetwork
        open={wrongNetworkOpen}
        />

      {/* Coin Swapper */}
      <Container maxWidth="xs" className={initialised ? 'swap-container' : 'swap-container no-wallet'}>
        <Paper className={classes.paperContainer}>
          <Typography variant="h5" className={classes.title}>Zentinel</Typography>
          <Typography variant="h6" className={classes.subtitle}>
            Zentinel Swap is only for the purchase of Zev³ Tokens. It does not support buying or selling of any other tokens at this time
          </Typography>
          <Grid container direction="column" alignItems="center" spacing={5} className={classes.paddedContainer}>
            <Grid item xs={12} className={classes.fullWidth }>
              <CoinField
                activeField={true}
                value={field1Value}
                onClick={() => setDialog1Open(true)}
                onChange={field1HandleChange.field1}
                symbol={coin1.symbol !== undefined ? coin1.symbol : "Select"}
              />
              <Typography variant="body1" className={classes.balance}>{'Balance: ' + formatBalance(coin1.balance, coin1.decimals)} <a className={initialised ? classes.maxHyper : classes.disabled} onClick={setCoinOneMax}>Max</a></Typography>
            </Grid>

            <IconButton onClick={switchFields} className={classes.switchButton}>
              <SwapVerticalCircleIcon fontSize="medium" />
            </IconButton>

            <Grid item xs={12} className={classes.fullWidth}>
              <CoinField
                activeField={true}
                value={field2Value}
                onClick={() => setDialog2Open(true)}
                onChange={field2HandleChange.field2}
                symbol={coin2.symbol !== undefined ? coin2.symbol : "Select"}
              />
              <Typography variant="body1" className={classes.balance}>
                <div className={isZentinel ? classes.balanceContainer : ''}>
                  {'Balance: ' + formatBalance(coin2.balance, coin1.decimals)} <a className={initialised ? classes.maxHyper : classes.disabled} onClick={setCoinTwoMax}>{isZentinel ? 'Max Buy' : 'Max'}</a>
                </div>
              </Typography>
            </Grid>

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              size="invisible"
              className={classes.fullWidth} 
              ref={reRef}
            />

            {/* Reserves Display 
            <Typography variant="h6">Reserves</Typography>
            <Grid container direction="row" justifyContent="space-between">
              <Grid item xs={6}>
                <Typography variant="body1" className={classes.balance}>
                  {formatReserve(reserves[0], coin1.symbol)}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body1" className={classes.balance}>
                  {formatReserve(reserves[1], coin2.symbol)}
                </Typography>
              </Grid>
            </Grid>

            <hr className={classes.hr} />*/}

            <Grid container item xs={12} id="swapBtn-container" className={classes.fullWidth} spacing={0}>
              <Grid item xs={6}>
                <FormControl variant="outlined" sx={{ m: 1, mt: 3, width: '25ch' }}>
                  <OutlinedInput
                    endAdornment={<InputAdornment position="end">%</InputAdornment>}
                    inputProps={{
                      'aria-label': 'slippage',
                      style: {
                        fontSize: 14,
                        height: 36.5,
                        width: 100,
                        padding: '0 14px',
                        fontWeight: 'bold'
                      },
                    }}
                    value={slippage}
                    type="number" 
                    onChange={event => setSlippage(event.target.value)}
                    aria-describedby="slippage-ht"
                  />
                  <FormHelperText id="slippage-ht">Slippage</FormHelperText>
                </FormControl>

              </Grid>
              <Grid item xs={6}>
                <LoadingButton
                loading={loading}
                valid={isButtonEnabled()}
                success={false}
                fail={false}
                onClick={swap}
                className="swap-button"
              >
                {!loading ? <LoopIcon /> : ''}
                {!loading ? 'Swap' : 'Swapping'}
              </LoadingButton>
              </Grid>
              {tokenSummaryOutput.length > 0 ? <div className="tokenSummaryContainer" dangerouslySetInnerHTML={{ __html: tokenSummaryOutput }} /> : ''}
              {isZentinel ? <div className='swapDisclaimer'><p>{disclaimerMessage}</p></div>: ''}
            </Grid>

          </Grid>
        </Paper>
      </Container>

      {/*<Grid
        container
        className={classes.footer}
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
      >

      </Grid>*/}
    </>
  );
}

export default Swap;