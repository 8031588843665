import React, { useState } from 'react';
import { Form, Formik, FormikConfig, FormikValues, useFormikContext } from 'formik';
import { 
    Button, 
    CircularProgress, 
    Grid, 
    Step, 
    StepLabel, 
    Stepper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Typography
} from "@material-ui/core";
import ReactRouterPrompt from 'react-router-prompt';

export interface FormikStepProps
  extends Pick<FormikConfig<FormikValues>, 'children' | 'validationSchema'> {
  label: string;
}

export function FormikStep({ children }: FormikStepProps) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }: FormikConfig<FormikValues>) {
  const tmpArr = children || [];
  const childrenArray = [tmpArr[0],tmpArr[1],tmpArr[2],tmpArr[3],tmpArr[4]];
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  const PromptIfDirty = () => {
    const formik = useFormikContext();

    return (
      <ReactRouterPrompt when={formik.dirty && formik.submitCount === 0}>
        {({ isActive, onConfirm, onCancel }) => (
          <Dialog
            open={isActive}
            keepMounted
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle>{"Unsaved Changes"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                You are about to navigate away from ZEV³, which means you will lose your progress. Would you like to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" className='error-button' onClick={onCancel}>Cancel</Button>
              <Button variant="contained" className="primary-button2" onClick={onConfirm}>Continue</Button>
            </DialogActions>
          </Dialog>
        )}
      </ReactRouterPrompt>
    );
  };

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        } 
        else {
          setStep((s) => s + 1);
          helpers.setTouched({});
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <PromptIfDirty/>
          <div className='zev-header'>
            <Typography variant="h4" >
                {step >= 0 && step < 2 ? 'ZEV³ Contract Creator' : 'ZEV Launcher'}
            </Typography>
            <Stepper alternativeLabel activeStep={step}>
              {childrenArray.map((child, index) => (
                <Step key={child.props.label} completed={step > index || completed}>
                  
                  <StepLabel><span dangerouslySetInnerHTML={{__html: child.props.label.replaceAll("& ", "<br/>& ")}}></span></StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          {currentChild}
              
          <hr className='stepper-seperator'/>

          <Grid container spacing={2} className="btn-container">
            {step > 0 ? (
              <Grid item >
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  onClick={() => setStep((s) => s - 1)}
                >
                  Back
                </Button>
              </Grid>
            ) : null}
            <Grid item>
              <Button
                startIcon={isSubmitting ? <CircularProgress style={{'color': '#fff'}} size="1rem" /> : null}
                disabled={isSubmitting}
                variant="contained"
                color="primary"
                type="submit"
              >
                {isSubmitting ? 'Creating...' : isLastStep() ? 'Create Contract' : 'Next'}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}