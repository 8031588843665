import { ButtonBase, Grid, makeStyles, Typography } from "@material-ui/core";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    "&:hover, &$focusVisible": {
      backgroundColor: '#2e2e2e'
    },
  },
  
  coinName: {
    opacity: 0.8,
    color: "aliceblue"
  },
}));

CoinButton.propTypes = {
  coinName: PropTypes.string.isRequired,
  coinAbbr: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default function CoinButton(props) {
  const { coinName, coinAbbr, onClick, ...other } = props;
  const classes = useStyles();

  return (
    <ButtonBase focusRipple className={classes.button} onClick={onClick}>
      <Grid container direction="column">
        <Typography variant="h6" ><span className={classes.coinName}>{coinName}</span> ({coinAbbr})</Typography>
      </Grid>
    </ButtonBase>
  );
}
