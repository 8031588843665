import { Contract, ethers } from "ethers";
import { fromWei, toWei } from './utils';
import * as chains from "./Constants/chains";

const ROUTER = require("./build/UniswapV2Router02.json");
const ERC20 = require("./build/ERC20.json");
const FACTORY = require("./build/IUniswapV2Factory.json");
const PAIR = require("./build/IUniswapV2Pair.json");

export function getBlockNumber(provider) {
  return provider.getBlockNumber();
}

export function getBlock(provider, blockNumber) {
  return provider.getBlock(blockNumber);
}

export function getProvider() {
  return new ethers.providers.Web3Provider(window.ethereum);
}

export function getSigner(provider) {
  return provider.getSigner();
}

export async function getNetwork(provider) {
  const network = await provider.getNetwork();
  return network.chainId;
}

export function getRouter(address, signer) {
  return new Contract(address, ROUTER.abi, signer);
}

export async function checkNetwork(provider) {
  const chainId = getNetwork(provider);
  if (chains.networks.includes(chainId)){
    return true
  }
  return false;
}

export function getWeth(address, signer) {
  return new Contract(address, ERC20.abi, signer);
}

export function getFactory(address, signer) {
  return new Contract(address, FACTORY.abi, signer);
}

export async function getAccount() {
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  return accounts[0];
}

export function doesTokenExist(address, signer) {
  try {
    return new Contract(address, ERC20.abi, signer);
  } 
  catch (err) {
    return false;
  }
}

export function doesTokenExistBool(address, signer) {
  try {
    return true;
  } 
  catch (err) {
    return false;
  }
}

export async function getDecimals(token) {
  const decimals = await token.decimals().then((result) => {
    return result;
  })
  .catch((error) => {
    return 0;
  });

  return decimals;
}

export async function getBalanceAndSymbol(
  accountAddress,
  address,
  provider,
  signer,
  weth_address,
  coins
) {
  try {
    if (address === weth_address) {
      const balanceRaw = await provider.getBalance(accountAddress);

      return {
        balance: ethers.utils.formatEther(balanceRaw),
        symbol: coins[0].abbr,
      };

    } else {
      const token = new Contract(address, ERC20.abi, signer);
      const tokenDecimals = await getDecimals(token);
      const symbol = await token.symbol();
      const name = await token.name();
      const balanceRaw = await token.balanceOf(accountAddress);

      return {
        symbol: symbol,
        decimals: tokenDecimals,
        name: name,
        balance: fromWei(balanceRaw.toString(), tokenDecimals).toString()
      };
    }
  } 
  catch (error) {
    console.log (error);
    return false;
  }
}

export async function getAllowance(token, accountAddress, spenderAddress) {
  const decimals = await token.allowance(accountAddress, spenderAddress).then((result) => {
    return result;
  })
  .catch((error) => {
    return 0;
  });

  return decimals;
}

export async function swapTokens(
  address1,
  address2,
  amount,
  routerContract,
  accountAddress,
  signer,
  slippage
) {
  const tokens = [address1, address2];
  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = ethers.BigNumber.from(time);

  const token1 = new Contract(address1, ERC20.abi, signer);
  const tokenDecimals = await getDecimals(token1);
  
  const amountIn = toWei(amount, tokenDecimals);
  const amountOut = await routerContract.callStatic.getAmountsOut(
    amountIn,
    tokens
  );
  
  const amountOutFromWei = fromWei(amountOut[1].toString(), tokenDecimals);
  const amountOutWithSlippage = parseFloat(amountOutFromWei - (amountOutFromWei * slippage)).toFixed(tokenDecimals);
  const amountOutTotal = toWei(amountOutWithSlippage.toString(), tokenDecimals);
  const allowance = await getAllowance(token1, accountAddress, routerContract.address);
  const wethAddress = await routerContract.WETH();

  let transaction = null;

  if (address1 === wethAddress) {
    // Eth -> Token
    // If doesn't work try: swapExactETHForTokensSupportingFeeOnTransferTokens

    transaction = await routerContract.swapExactETHForTokens(
      amountOutTotal.toString(),
      tokens,
      accountAddress,
      deadline,
      { value: amountIn }
    );
  } 
  else if (address2 === wethAddress) {
    if (parseFloat(allowance.toString()) < parseFloat(amountIn.toString())) {
      const approveTransaction = await token1.approve(routerContract.address, "115792089237316195423570985008687907853269984665640564039457584007913129639935");
      await approveTransaction.wait();
    }

    // Token -> Eth
    // Old swapExactTokensForETH
    transaction = await routerContract.swapExactTokensForETHSupportingFeeOnTransferTokens(
      amountIn,
      amountOutTotal.toString(),
      tokens,
      accountAddress,
      deadline
    );
  } 
  else {
    if (parseFloat(allowance.toString()) < parseFloat(amountIn.toString())) {
      const approveTransaction = await token1.approve(routerContract.address, "115792089237316195423570985008687907853269984665640564039457584007913129639935");
      await approveTransaction.wait();
    }

    // Token -> Token
    // Old: swapExactTokensForTokens
    transaction = await routerContract.swapExactTokensForTokensSupportingFeeOnTransferTokens(
      amountIn,
      amountOutTotal.toString(),
      tokens,
      accountAddress,
      deadline
    );
  }

  await transaction.wait().then((result) => {
    return result;
  });
}

export async function swapZevTokens(
  address1,
  address2,
  amount,
  routerContract,
  signer,
  sigObj,
  ZentinelContract,
  slippage,
  nonce
) {
  const tokens = [address1, address2];
  const token1 = new Contract(address1, ERC20.abi, signer);
  const tokenDecimals = await getDecimals(token1);
  const amountIn = toWei(amount, tokenDecimals);
  const amountOut = await routerContract.callStatic.getAmountsOut(amountIn, tokens);
  const wethAddress = await routerContract.WETH();

  if (address1 === wethAddress) {
      let amountOutFromWei = fromWei(amountOut[1].toString());
      let amountOutWithSlippage = amountOutFromWei - (amountOutFromWei * slippage);
      let amountOutTotal = toWei(amountOutWithSlippage.toString());

      const transaction = await ZentinelContract.authorizedBuyExactETHForToken(
        tokens,
        sigObj.v,
        sigObj.r,
        sigObj.s,
        amountOutTotal.toString(),
        nonce,
        {
          value: amountIn.toString()
        }
      );

      await transaction.wait().then((result) => {
        return result;
      });
  }
}

export async function getAmountOut(
  address1,
  address2,
  amountIn,
  routerContract,
  signer
) {
  try {
    const token1 = new Contract(address1, ERC20.abi, signer);
    const token1Decimals = await getDecimals(token1);

    const token2 = new Contract(address2, ERC20.abi, signer);
    const token2Decimals = await getDecimals(token2);

    const values_out = await routerContract.getAmountsOut(
      toWei(String(amountIn), token1Decimals),
      [address1, address2]
    );
    const amount_out = values_out[1]*10**(-token2Decimals);
    return Number(amount_out);
  } catch {
    return false;
  }
}

export async function fetchReserves(address1, address2, pair, signer) {
  try {

    // Get decimals for each coin
    const coin1 = new Contract(address1, ERC20.abi, signer);
    const coin2 = new Contract(address2, ERC20.abi, signer);

    const coin1Decimals = await getDecimals(coin1);
    const coin2Decimals = await getDecimals(coin2);

    // Get reserves
    const reservesRaw = await pair.getReserves();

    // Put the results in the right order
    const results =  [
      (await pair.token0()) === address1 ? reservesRaw[0] : reservesRaw[1],
      (await pair.token1()) === address2 ? reservesRaw[1] : reservesRaw[0],
    ];

    // Scale each to the right decimal place
    return [
      (results[0]*10**(-coin1Decimals)),
      (results[1]*10**(-coin2Decimals))
    ]
  } catch (err) {
    console.log(err);
    return [0, 0];
  }
}

export async function getReserves(
  address1,
  address2,
  factory,
  signer,
  accountAddress
) {
  try {
    const pairAddress = await factory.getPair(address1, address2);
    const pair = new Contract(pairAddress, PAIR.abi, signer);
  
    if (pairAddress !== '0x0000000000000000000000000000000000000000'){
  
      const reservesRaw = await fetchReserves(address1, address2, pair, signer);
      const liquidityTokens_BN = await pair.balanceOf(accountAddress);
      const liquidityTokens = Number(
        ethers.utils.formatEther(liquidityTokens_BN)
      );
    
      return [
        reservesRaw[0].toPrecision(6),
        reservesRaw[1].toPrecision(6),
        liquidityTokens,
      ];
    } else {
      console.log("no reserves yet");
      return [0,0,0];
    }
  }catch (err) {
    console.log(err);
    return [0, 0, 0];
  }
}