import React from "react";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import CoinButton from "./CoinButton";
import { doesTokenExist } from "../../etherFunctions";
import PropTypes from "prop-types";

const styles = (theme) => ({
  dialogContainer: {
    borderRadius: theme.spacing(2),
    borderColor: '#3a3a3a',
    background: '#161618!important',
    padding: "0px!important"
  },
  titleSection: {
    padding: theme.spacing(2),
  },
  titleText: {
    alignSelf: "center",
    color: '#fff!important'
  },
  hr: {
    margin: 0,
    borderColor: '#5a5a5a'
  },
  address: {
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    paddingBottom: theme.spacing(2),
  },
  coinList: {
    maxHeight: "350px",
    overflowY: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center"
  },
  coinContainer: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: "0px",
    marginTop: "0px",
    overflow: "hidden",
  },
  dialogActions: {
    background: '#161618!important'
  },
  dialogButton: {
    color: "#36c",
    "&:hover": {
      color: "#2854ab",
    },
  },
  coinItem: {
    borderTop: "1px solid #5a5a5a"
  },
  mcMsg: {
    padding: "25px"
  }
});

const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      disableTypography
      className={classes.titleSection}
      {...other}
    >
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignContent="center"
      >
        <Typography variant="h6" className={classes.titleText}>
          {children}
        </Typography>
        {onClose ? (
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Grid>
    </MuiDialogTitle>
  );
});

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: '#000'
  },
}))(MuiDialogActions);

CoinDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  coins: PropTypes.array.isRequired,
};

export default function CoinDialog(props) {
  const classes = useStyles();
  const { onOpen, onClose, open, coins, signer, ...others } = props;

  const [address, setAddress] = React.useState("");
  const [error, setError] = React.useState("");

  const submit = () => {
    if (doesTokenExist(address, signer)) {
      exit(address);
    } else {
      setError("This address is not valid");
    }
  };

  const exit = (value) => {
    setError("");
    setAddress("");
    onClose(value);
  };

  return (
    <Dialog
      open={open}
      onClose={() => exit(undefined)}
      fullWidth
      maxWidth="xs"
      classes={{ paper: classes.dialogContainer }}
    >
      <DialogTitle onClose={() => exit(undefined)}>Select Coin</DialogTitle>

      <div className={classes.coinContainer}>
        <Grid container direction="column" spacing={-15} alignContent="center">
          <TextField
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            variant="outlined"
            placeholder="Enter Custom Address"
            error={error !== ""}
            helperText={error}
            fullWidth
            className={classes.address}
          />

          <hr className={classes.hr} />

          <span className={classes.mcMsg}>Most Common Tokens</span>

          <Grid item className={classes.coinList}>
            <Grid container direction="column">
              {coins.map((coin, index) => (
                <Grid item key={index} xs={12} className={classes.coinItem}>
                  <CoinButton
                    coinName={coin.name}
                    coinAbbr={coin.abbr}
                    onClick={() => exit(coin.address)}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </div>

      <DialogActions className={classes.dialogActions}>
        <Button autoFocus onClick={submit} className={classes.dialogButton}>
          SELECT COIN
        </Button>
      </DialogActions>
    </Dialog>
  );
}