export const networks = [1,5,56,97]

export const ChainId = {
  ETH: 1,
  GÖRLI: 5,
  BNB: 56,
  BNBTEST: 97
};

export const routerAddress = new Map();
routerAddress.set(ChainId.ETH, "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D");
routerAddress.set(ChainId.GÖRLI, "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D");
routerAddress.set(ChainId.BNB, "0x10ED43C718714eb63d5aA57B78B54704E256024E");
routerAddress.set(ChainId.BNBTEST, "0xD99D1c33F9fC3444f8101754aBC46c52416550D1");

export const currencyName = new Map();
currencyName.set(ChainId.ETH, "ETH");
currencyName.set(ChainId.GÖRLI, "GoerliETH");
currencyName.set(ChainId.BNB, "BNB");
currencyName.set(ChainId.BNBTEST, "BNB");

export const explorerURL = new Map();
explorerURL.set(ChainId.ETH, "https://etherscan.io");
explorerURL.set(ChainId.GÖRLI, "https://goerli.etherscan.io");
explorerURL.set(ChainId.BNB, "https://bscscan.com");
explorerURL.set(ChainId.BNBTEST, "https://testnet.bscscan.com");