import { Routes, Route  } from "react-router-dom";
import { ThirdwebProvider } from "@thirdweb-dev/react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SnackbarProvider } from "notistack";
import TaskBar from "./Components/TaskBar/";
import DAO from "./Pages/DAO";
import Calculator from "./Pages/Calculator";
import Zev from "./Pages/Zev/index";
import Tesseract from "./Pages/Tesseract";
import FAQ from "./Pages/FAQ";
import SideBar from "./Components/SideBar";
import Swap from "./Pages/Swap";
import Locker from "./Pages/Locker";
import Affiliates from "./Pages/Affiliates";
import Providers from "./Pages/Providers";
import Launches from "./Pages/Launches";
import Token from "./Pages/Token";
import Vaultz from "./Pages/Vaultz";
import { ProSidebarProvider } from "react-pro-sidebar";
import ContractsProvider from "./contractsProvider";

const theme = createTheme({
  palette: {
    primary: {
      main: "#36c",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#9e9e9e",
      contrastText: "#ffffff",
    },
  },
});

export default function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ProSidebarProvider>
          <ThirdwebProvider>
            <ContractsProvider>
              <SnackbarProvider maxSnack={3} classes={{containerRoot: 'snackbar'}}>
                  <SideBar />
                  <main>
                    <TaskBar />
                    <Routes>
                      <Route exact path="/" element={<Zev />} />
                      <Route path="/Zev" element={<Zev />} />
                      <Route path="/Swap" element={<Swap />} />
                      <Route path="/Tesseract" element={<Tesseract />} />
                      <Route path="/FAQ" element={<FAQ />} />
                      <Route path="/Providers" element={<Providers />} />
                      <Route path="/Launches" element={<Launches />} />
                      <Route path="/Token" element={<Token />} />
                      <Route path="/Vaultz" element={<Vaultz />} />
                    </Routes>
                  </main>
              </SnackbarProvider>
            </ContractsProvider>
          </ThirdwebProvider>
        </ProSidebarProvider>
      </ThemeProvider>
    </div>
  );
}
