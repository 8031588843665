import { ethers } from "ethers";
import { getParsedEthersError } from "@enzoferey/ethers-error-parser";

export const capitalize = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

export const convertTimestampToDate = (date) => {
    let dateStr = date.toString();
    var t = new Date(parseInt(dateStr) * 1000);
    return t.toLocaleString();
};

export const convertTimestampToShortDate = (date) => {
    var t = new Date(parseInt(date) * 1000);
    var formatted = t.toISOString();
    return formatted;
};

export const formatNumbers = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatWeiParsed = (data, decimals = 0) => { 
    let wei = ethers.utils.formatEther(data.toString(), 'ether');
    return parseFloat(wei).toFixed(decimals);
}

export const formatWei = (data) => {
    return ethers.utils.formatEther(data.toString(), 'ether');
}

export const toWei = (value, decimals = 'ether') => {
    return ethers.utils.parseUnits(value, decimals);
}

export const toPercentageDelimiter = (percentage) => {
    return parseFloat(percentage) * 100;
};

export const fromWei = (value, decimals = 'ether') => {
    return ethers.utils.formatEther(value, decimals);
}

export const getNonce = () => {
    const arr = ethers.utils.randomBytes(25);
    let nonce = arr.toString().replaceAll(",","");
    return nonce;
};

export const numberExponentToLarge = (n) => {
    var sign = +n < 0 ? "-" : "", toStr = n.toString();
    if (!/e/i.test(toStr)) { return n; }
    var [lead, decimal, pow] = n.toString().replace(/^-/, "").replace(/^([0-9]+)(e.*)/, "$1.$2").split(/e|\./);
    return +pow < 0 ?sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal : sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)))
}

export const getEtherErrorContextMessage = (err) => {
    const parsedEthersError = getParsedEthersError(err);
    const context = parsedEthersError.context;
    let toReturn = parsedEthersError.context;

    switch(context) {
        case "Ownable: caller is not the owner":
            toReturn = "You do not own this referral code!";
        break;
        case "Code already used":
            toReturn = "This referral code has already been taken";
        break;
    }

    console.log(context);

    if (context) {
        if (context.indexOf("user rejected transaction") > -1) {
            toReturn = "You have rejected the transaction";
        }
    }

    return toReturn;
};

export const getEtherErrorMessage = (err) => {
    const parsedEthersError = getParsedEthersError(err);
    const errCode = parsedEthersError.errorCode;
    const context = parsedEthersError.context;

    let toReturn = errCode;

    switch(errCode) {
        case "TRANSACTION_RAN_OUT_OF_GAS":
            toReturn = "The Transaction ran out of gas.";
        break;
        case "TRANSACTION_UNDERPRICED":
            toReturn = "The transaction Gas Limit is too low.";
        break;
        case "REJECTED_TRANSACTION":
            toReturn = "The transaction was rejected by the user.";
        break;
        case "CALL_REVERTED":
            toReturn = "The transaction call was reverted.";
        break;
        case "EXECUTION_REVERTED":
            toReturn = "The transaction execution was reverted.";
        break;
        case "NONCE_TOO_LOW":
            toReturn = "The transaction nonce is too low.";
        break;
        case "INSUFFICIENT_FUNDS_FOR_GAS":
            toReturn = "You do not have enough funds for the gas fee.";
        break;
        case "MAX_PRIORITY_FEE_PER_GAS_HIGHER_THAN_MAX_FEE_PER_GAS":
            toReturn = "Max priority fee per gas is higer than max fee per gas.";
        break;
        case "MAX_FEE_PER_GAS_LESS_THAN_BLOCK_BASE_FEE":
            toReturn = "Max fee per gas is less than block base fee.";
        break;
        case "UNKNOWN_ERROR":
            toReturn = errCode;
        break;
        default: 
            toReturn = errCode;
        break;
    }

    switch(context) {
        case "ReferralRegistry: Referral code invalid":
            toReturn = "Referral Code is invalid";
        break;
    }

    if (context) {
        if (context.indexOf("insufficient funds for gas") > -1) {
            toReturn = "You do not have enough ETH for liquidity or gas";
        }
    }

    return toReturn;
};

export const convertCurrency = (labelValue) => {
    if (labelValue < 0 || labelValue > 100000000000000) { return "---"; }
    return Math.abs(parseInt(labelValue)) >= 1.0e+12
    ? Math.abs(parseInt(labelValue)) / 1.0e+12 + "T"
    :Math.abs(parseInt(labelValue)) >= 1.0e+9
    ? Math.abs(parseInt(labelValue)) / 1.0e+9 + "B"
    : Math.abs(parseInt(labelValue)) >= 1.0e+6
    ? Math.abs(parseInt(labelValue)) / 1.0e+6 + "M"
    : Math.abs(parseInt(labelValue)) >= 1.0e+3
    ? Math.abs(parseInt(labelValue)) / 1.0e+3 + "K"
    : Math.abs(parseInt(labelValue));
};

export const roundUp = (date) => {
    return date.endOf('hour').add(1, 'minute');
};

export const shortenHash = (hash) => {
    return hash.substring(0,5) + "..." + hash.substring(61,66);
} 

export const getNetworkName = (id) => {
    let toReturn = "##";

    switch(id) {
        case 1:
            toReturn = "Ethereum";
        break;
        case 56:
            toReturn = "BNB";
        break;
    }
    
    return toReturn;
}

export const getAvailableNetworks = (networkArr) => {
    let networks = "";

    if (networkArr.testnet) { return; }

    for (let i = 0; i < networkArr.length; i++) {
      if (networkArr.length === 1) {
        networks += getNetworkName(networkArr[i]);
      }
      else if (i === (networkArr.length - 1)) {
        networks += getNetworkName(networkArr[i]);
      }
      else {
        networks += getNetworkName(networkArr[i]) + ", ";
      }
    }

    return networks.replaceAll(", ##", "");
  };