import React, { useEffect, useContext } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { Link, useLocation } from "react-router-dom";
import { Typography } from "@material-ui/core";
import "./index.css";
import {
    Home,
    Addchart,
    SwapHorizontalCircle,
    HelpOutline,
    Telegram,
    Twitter,
    Article,
    Email,
    KeyboardArrowRight,
    ViewInAr,
    LockClock,
    Group,
    EventNote,
    Tune
} from "@material-ui/icons";
import { Contracts } from "../../contractsProvider";

let location = {
    pathname: null
};

function SideBar(props) {
    const {collapsed, toggleSidebar} = useProSidebar();
    const {z3Price} = useContext(Contracts);
    const [activeIndex, setActiveIndex] = React.useState(-1);
    location = useLocation();

    const handleNavClick = () => {
        toggleSidebar();
    };

    const checkLocation = () => {
        const initialIndex = location.pathname === '/' ? 0 
        : location.pathname === '/Zev' ? 1 
        : location.pathname === '/Swap' ? 2 
        : location.pathname === '/Tesseract' ? 3
        : location.pathname === '/Calculator' ? 4
        : location.pathname === '/FAQ' ? 5
        : location.pathname === '/Locker' ? 6
        : location.pathname === '/Affiliates' ? 7
        : location.pathname === '/Providers' ? 8
        : location.pathname === '/Launches' ? 9
        : location.pathname === '/Token' ? 10
        : location.pathname === '/Vaultz' ? 11
        : -1; 

        if (initialIndex > -1) {
            setActiveIndex(initialIndex);
        }
    };

    useEffect(() => {
        checkLocation();
    }, [location]);

    return (
        <Sidebar breakPoint="md" style={{ height: "100vh", margin: "0px", padding: "0px" }}>
            <Menu>
                <div className="logo-area">
                    {collapsed ? <img alt='' src='icon.png' height="50px"/> : <img alt='' src='logo.png' height="50px"/> }
                </div>
                <div style={{ padding: '0 24px', marginBottom: '8px' }}>
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px', color: "#fff", paddingBottom: "10px" }}
                    >
                        Navigation
                    </Typography>
                </div>
                <a href="https://z-3.io" className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 99} icon={<Home />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>Home</Typography>
                    </MenuItem>
                </a>
                <Link to='/Zev' className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 1} icon={<Addchart />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>ZEV³ V2</Typography>
                    </MenuItem>
                </Link>
                <Link to='/Swap' className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 2} icon={<SwapHorizontalCircle />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>Zentinel Swap</Typography>
                    </MenuItem>
                </Link>
                <Link to='/Token' className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 10} icon={<Tune />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>Manage Token</Typography>
                    </MenuItem>
                </Link>
                <Link to='/Launches' className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 9} icon={<EventNote />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>Launch Calendar</Typography>
                    </MenuItem>
                </Link>
                <Link to='/Vaultz' className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 11} icon={<LockClock />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>Vaultz</Typography>
                    </MenuItem>
                </Link>
                <Link to='/Tesseract' className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 3} icon={<ViewInAr />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>Tesseract</Typography>
                    </MenuItem>
                </Link>
                <Link to='/Providers' className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 8} icon={<Group />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>Services</Typography>
                    </MenuItem>
                </Link>
                <div style={{ padding: '0 24px', marginBottom: '8px' }}>
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px', color: "#fff", paddingBottom: "10px", marginTop:"20px" }}
                    >
                        Socials
                    </Typography>
                </div>
                <a href='https://t.me/Z3_Portal' target="_blank" className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 99} icon={<Telegram />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>Telegram</Typography>
                    </MenuItem>
                </a>
                <a href='https://medium.com/@zcubed.erc' target="_blank" className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 99} icon={<Article />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>Medium</Typography>
                    </MenuItem>
                </a>
                <a href='https://twitter.com/Z3_ERC' target="_blank" className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 99} icon={<Twitter />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>Twitter</Typography>
                    </MenuItem>
                </a>
                <div style={{ padding: '0 24px', marginBottom: '8px' }}>
                    <Typography
                        variant="body2"
                        fontWeight={600}
                        style={{ opacity: collapsed ? 0 : 0.7, letterSpacing: '0.5px', color: "#fff", paddingBottom: "10px", marginTop:"20px" }}
                    >
                        Support
                    </Typography>
                </div>
                <Link to='/FAQ' className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 5} icon={<HelpOutline />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>FAQ</Typography>
                    </MenuItem>
                </Link>
                <a href='mailto:z@z-3.io' className="menu-item-link" onClick={handleNavClick}>
                    <MenuItem active={activeIndex === 99} icon={<Email />} suffix={<KeyboardArrowRight/>}>
                        <Typography component={'span'}>Email Us</Typography>
                    </MenuItem>
                </a>

                {!collapsed ? 
                    <div className="cubes">
                        <div className="cubes-container">
                            <div className="cubes-stats-container">
                                <Typography component={'span'}>Z³ Price&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                            </div>
                            <div className="cubes-stats-container cubes-stats-value">
                                <Typography component={'span'}>${z3Price ? z3Price : ' ---'}</Typography>
                            </div>
                        </div>
                    </div>
                    
                    : ""
                }
            </Menu>
        </Sidebar>
    );
}
    
export default SideBar;