import { useAddress, useSigner, useChainId } from "@thirdweb-dev/react";
import { Fragment, createRef, useEffect, useState } from 'react';
import { 
    Container,
    Paper,
    Typography,
    makeStyles,
    Grid,
    Dialog,
    DialogActions,
    DialogContent,
    Button,
    DialogTitle,
    CircularProgress
} from "@material-ui/core";
import './index.css';
import { getBlockNumber, getBlock } from "../../etherFunctions";
import { ethers } from "ethers";
import FullCalendar from '@fullcalendar/react';
import multiMonthPlugin from '@fullcalendar/multimonth';
import dayGridPlugin from '@fullcalendar/daygrid';
import { useNavigate } from 'react-router-dom';
import listPlugin from '@fullcalendar/list';
import { convertTimestampToShortDate, convertCurrency, convertTimestampToDate, fromWei, numberExponentToLarge } from '../../utils'

let signer = null;
let connectedAddress = null;
let chainId = null;

export default function Launches(props) {
    const styles = (theme) => ({
        paperContainer: {
            padding: theme.spacing(5),
            paddingBottom: theme.spacing(3),
        },
        title: {
            textAlign: "left"
        },
        fullWidth: {
            width: "100%",
        },
        innerContainer: {
            marginTop: "20px",
        },
        info: {
            textAlign: "justify",
            paddingTop: "15px",
            paddingBottom: "15px"
        },
        progressContainer: {
            textAlign: "center",
            marginBottom: "20px"
        }
    });

    const useStyles = makeStyles(styles);
    const navigate = useNavigate();
    const [allEvents, setAllEvents] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const classes = useStyles();
    const calendarRef = createRef();
    const [launchOutput, setLaunchOutput] = useState('');
    const [selectedToken, setselectedToken] = useState(null);
    const [loadedTokens, setLoadedTokens] = useState('Downloading all Zev³ tokens...');
    const [selectedLoading, setSelectedLoading] = useState(false);
    const [isLive, setIsLive] = useState(false);

    const getAllTokens = async() => {
        var response = {
          'data' : [],
          'status' : false
        };

        let tokenURL = "/scripts/getTokens.php";

        if (process.env.REACT_APP_NETWORK === "localhost") {
            tokenURL = "https://zev2publictesting.gemclub.cc/scripts/getTokens.php";
        }

        await fetch(tokenURL, { 
          method: "POST",
          body: JSON.stringify({
            api_key: process.env.REACT_APP_RECAPTCHA_API_KEY,
            branch: process.env.REACT_APP_NETWORK === "production" ? "main" : "dev",
            chainId: chainId
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8'
          }
        })
        .then((response) => response.json())
        .then((responseData) => {
            if (responseData.status) {
                const data = JSON.parse(responseData.data);
                var formattedArr = [];

                data.forEach(function(token, t) {
                    if (token[10].args) {
                        const params = token[10].args.params;

                        formattedArr.push({
                            'contractAddress' : token[1],
                            'token_name': params._name,
                            'token_symbol': params._symbol,
                            'token_decimals': token[4],
                            'token_supply': numberExponentToLarge(params._supply),
                            'buy_tax': params._buyTax,
                            'sell_tax': params._sellTax,
                            'max_transaction': params._maxTransaction,
                            'max_wallet': params._maxWallet,
                            'launch_time': params._launchTime
                        });
                    }
                });

                response = {"status" : true, data: formattedArr};;
            }
            else {
                response = {"status" : true, data: []};
            }
        })
        .catch(error => console.warn(error));
    
        return response;
    };

    const handleClick = async(id) => {
        setLaunchOutput("<table><tr><thead><th align='center'>Contract Address</th><th align='center'>Token Symbol</th><th align='center'>Token Supply</th><th align='center'>Token Decimals</th><th align='center'>Launch Time</th><th align='center'>Buy Tax</th><th align='center'>Sell Tax</th></tr></thead><tr class='noLine'><td data-label='Contract Address' align='center'>Loading...</td><td data-label='Token Symbol' align='center'>Loading...</td><td data-label='Token Decimals' align='center'>Loading...</td><td data-label='Token Supply' align='center'>Loading...</td><td data-label='Launch Date' align='center'>Loading...</td><td data-label='Buy Tax' align='center'>Loading...</td><td data-label='Sell Tax' align='center'>Loading...</td></tr></table>");
        setModalOpen(true);
        setSelectedLoading(true);

        const selected = allEvents[id];
        const symbol = selected.symbol;
        const decimals = selected.decimals;
        const totalBuyTax = parseInt(selected.buyTax) / 100;
        const totalSellTax = parseInt(selected.sellTax) / 100;
        const totalSupply = selected.token_supply;
        const maxTransaction = parseInt(selected.maxTransaction) / 100;
        const maxWallet = parseInt(selected.maxWallet) / 100;

        if (selected.className === "live") {
            setIsLive(true);
        }
        else {
            setIsLive(false);
        }

        setLaunchOutput("<table><tr><thead><th align='center'>Contract Address</th><th align='center'>Token Symbol</th><th align='center'>Token Supply</th><th align='center'>Token Decimals</th><th align='center'>Launch Time</th><th align='center'>Buy Tax</th><th align='center'>Sell Tax</th><th align='center'>Max Transaction</th><th align='center'>Max Wallet</th></tr></thead><tr class='noLine'><td data-label='Contract Address' align='center'>"+ selected.contractAddress +"</td><td data-label='Token Symbol' align='center'>"+ symbol +"</td><td data-label='Token Decimals' align='center'>"+ decimals +"</td><td data-label='Token Supply' align='center'>"+ convertCurrency(totalSupply) +"</td><td data-label='Launch Date' align='center'>"+ convertTimestampToDate(selected.launchTime) +"</td><td data-label='Buy Tax' align='center'>"+ totalBuyTax +"%</td><td data-label='Sell Tax' align='center'>"+ totalSellTax +"%</td><td data-label='Max Transaction' align='center'>"+ maxTransaction +"%</td><td data-label='Max Wallet' align='center'>"+ maxWallet +"%</td></tr></table>");
        setselectedToken(selected);
        setSelectedLoading(false);
    }

    const handleClose = () => {
        setModalOpen(false);
    };

    const handleResult = (flag) => {
        if (!selectedToken) { return; }
        flag === 0 ? navigate('/Locker?token=' + selectedToken.contractAddress, { replace: true }) : navigate('/Swap?outputCurrency=' + selectedToken.contractAddress, { replace: true })
    };

    const initialise = async() => {
        const tokens = await getAllTokens();

        const zevTokens = [];

        if (tokens.data.length > 0) {
            setLoadedTokens("Getting block from blockchain...");
            
            const etherSigner = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
            const blockNumber = await getBlockNumber(etherSigner);
            const block = await getBlock(etherSigner, blockNumber);
            const timestamp = block.timestamp;

            for (let i = 0; i < tokens.data.length; i++) {
                try {
                    const name = tokens.data[i].token_name;
                    const symbol = tokens.data[i].token_symbol;
                    const decimals = tokens.data[i].token_decimals;
                    const tmpSupply = tokens.data[i].token_supply;
                    const token_supply = fromWei(tmpSupply, decimals);
                    const buyTax = tokens.data[i].buy_tax;
                    const sellTax = tokens.data[i].sell_tax;       
                    const maxTransaction = tokens.data[i].max_transaction;
                    const maxWallet = tokens.data[i].max_wallet;
                    const launchTime = tokens.data[i].launch_time;
                    
                    let className = "future"

                    if (launchTime < timestamp) {
                        className = "past";
                    }

                    //const tmpVerificationDuration = await tokenContract.verificationDuration();
                    const verificationDuration = launchTime + 1200;

                    if (timestamp > launchTime && timestamp < verificationDuration) {
                        className = "live";
                    }

                    zevTokens.push({
                        'id' : i,
                        'contractAddress' : tokens.data[i].contractAddress,
                        'name' : name,
                        'symbol' : symbol,
                        'decimals' : decimals,
                        'token_supply' : parseInt(token_supply).toString(),
                        'buyTax' : buyTax,
                        'sellTax' : sellTax,
                        'maxTransaction' : maxTransaction,
                        'maxWallet' : maxWallet,
                        'launchTime' : launchTime,
                        'title' : name + " ("+ symbol +")",
                        'start' : convertTimestampToShortDate(launchTime.toString()),
                        'end' : convertTimestampToShortDate(launchTime.toString()),
                        'color' : className,
                        'allDay' : false,
                        'className' : className
                    });

                    setLoadedTokens("Processing " + (i+1) + "/" + tokens.data.length + " Zev Tokens...");
                } 
                catch(ex) {
                    console.log(ex);
                }
            }
        }
        else {
            setLoadedTokens("Found no Zev³ Tokens");
        }

        setAllEvents(zevTokens);

        try {
            const calendar = calendarRef.current;
            calendar.requestResize();
        } 
        catch(ex) {
            console.log(ex);
        }
    };

    useEffect(() => {
        if (chainId !== null && typeof chainId !== "undefined") {
            initialise();
        }
    }, [connectedAddress]);

    signer = useSigner();
    connectedAddress = useAddress();
    chainId = useChainId();

    return(
    <>
        <Container maxWidth="lg">
            <Fragment>
                <Paper className={classes.paperContainer}>
                    <Typography variant="h4" className={classes.title}>
                        Upcoming Zev³ Launches
                    </Typography>
                    <Typography variant="h6" className={classes.title}>
                        Calendar view of all upcoming Zev³ Launches
                    </Typography>
                    <Grid container spacing={5} className='calendarContainer'>
                        {allEvents !== null ?
                        <FullCalendar
                            plugins= {[multiMonthPlugin, dayGridPlugin, listPlugin]}
                            initialView = {'dayGridMonth'}
                            ref={calendarRef} 
                            buttonText = {{
                                multiMonthYear: "Year",
                                dayGridMonth: "Month",
                                dayGridWeek: "Week",
                                listWeek: "List"
                            }}
                            headerToolbar = {{
                                left: 'prev,next',
                                center: 'title',
                                right: 'multiMonthYear dayGridMonth dayGridWeek listWeek'
                            }}
                            events = {allEvents}
                            eventClick = {(info) => handleClick(info.event.id)}
                            eventTimeFormat = {{
                                hour: 'numeric',
                                minute: '2-digit',
                                meridiem: 'short'
                            }}
                        />
                        : <div className='calendar-loading'><CircularProgress size="1.5rem"/><span>{loadedTokens}</span></div>}
                    </Grid>
                    <Dialog open={modalOpen} onClose={handleClose} className="result-dialog">
                        <div className={classes.modal}>
                            <DialogTitle>{selectedToken && !selectedLoading ? "Viewing Token: " + selectedToken.name : "Loading..."}</DialogTitle>
                            <DialogContent>
                                {selectedLoading ? <div className={classes.progressContainer}><CircularProgress size="2rem"/></div> : ""}
                                <div className="calendarOutputContainer" dangerouslySetInnerHTML={{ __html: launchOutput }} />
                            </DialogContent>
                            <DialogActions>
                                <Button variant="contained" disabled={isLive && !selectedLoading ? false : true} className="success" color="primary" onClick={() => handleResult(1)}>Swap Token</Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                </Paper>
            </Fragment>
        </Container>
    </>
    );
}