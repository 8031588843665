import * as chains from './chains';

const ETHCoins = [
  {
    name: "Ethereum",
    abbr: "ETH",
    address: "", 
    testnet: false
  }
]

const GÖRLICoins = [
  {
    name: "Ethereum",
    abbr: "ETH",
    address: "", 
    testnet: true
  }
]

const BNBCoins = [
  {
    name: "Binance",
    abbr: "BNB",
    address: "",
    testnet: false
  }
]

const BNBTESTCoins = [
  {
    name: "Smart Chain - Testnet",
    abbr: "BNB",
    address: "",
    testnet: true
  }
]

const COINS = new Map();

// Do not show testnet networks in production
if (process.env.REACT_APP_NETWORK === "production") {
  COINS.set(chains.ChainId.ETH, ETHCoins);
  COINS.set(chains.ChainId.BNB, BNBCoins);
}
else {
  COINS.set(chains.ChainId.ETH, ETHCoins);
  COINS.set(chains.ChainId.BNB, BNBCoins);
  COINS.set(chains.ChainId.GÖRLI, GÖRLICoins);
  //COINS.set(chains.ChainId.BNBTEST, BNBTESTCoins);
}

export default COINS
