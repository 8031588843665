import { Fragment } from 'react';
import { 
    Container,
    Paper,
    Typography,
    makeStyles,
    Grid,
    Button
} from "@material-ui/core";
import './index.css';


export default function Providers(props) {
    const styles = (theme) => ({
        paperContainer: {
            padding: theme.spacing(5),
            paddingBottom: theme.spacing(3),
        },
        title: {
            textAlign: "center"
        },
        fullWidth: {
            width: "100%",
        },
        innerContainer: {
            marginTop: "50px",
        },
        info: {
            textAlign: "justify",
            paddingTop: "15px",
            paddingBottom: "15px"
        },
        btnInfo: {
            borderTop: "1px solid #464646",
            paddingTop: "15px",
            textAlign: "center"
        },
        img: {
            textAlign: "center",
            paddingBottom: "15px"
        }
    });

    const handleButton = (flag) => {
        switch(flag) {
            case 0:
                window.open("https://solidproof.io/");
            break;
            case 1:
                window.open("https://assuredefi.com/");
            break;
        }
    };

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    return(
    <>
        <Container maxWidth="md" className='partnerContainer'>
            <Fragment>
                <Paper className={classes.paperContainer}>
                    <Typography variant="h4" className={classes.title}>
                        Our Service Providers
                    </Typography>
                    <Typography variant="h6" className={classes.title}>
                        Consider using one of Z³'s trusted partners for your project development needs
                    </Typography>
                    <Grid container spacing={5} className={classes.innerContainer}>
                        <Grid item spacing={2} md={6} xs={12}>
                            <Grid item md={12} className={classes.img}>
                                <img src="Assure.png" />
                            </Grid>
                            <Grid item md={12} className={classes.info}>
                                Assure DeFi is the pioneer and market leader of project KYC verifications in the crypto space. An Assure DeFi KYC adds trust and credibility to your project while also boosting visibility. Project founders rely on Assure’s data integrity and anonymity promise, key tenets of the crypto culture.  Unlock a world of exclusive benefits with Assure DeFi KYC: 
                                <br/>
                                <br/>- Universally recognized gold standard verification
                                <br/>- Custom 3D KYC NFT of compliance
                                <br/>- Verification approval announcements on social media
                                <br/>- Access to Assure Network Accelerator
                                <br/>- Custom marketing content
                                <br/>- And much more
                            </Grid>
                            <Grid item md={12} className={classes.btnInfo}>
                                <Button variant="contained" onClick={() => handleButton(1)} color="primary">
                                    Find Out More
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item spacing={2} md={6} xs={12}>
                            <Grid item md={12} className={classes.img}>
                                <img src="solidproof.png" />
                            </Grid>
                            <Grid item md={12} className={classes.info}>
                                SolidProof is Germany's largest registered security company focused on smart contract audits, KYC processes and development. The company was founded on May 30th, 2020 and has completed more than 1600 smart contract audits and up to 1000 KYCs since.<br/><br/>With many strong partnerships in various fields, the team is well-connected and has secured a spot among the leading security providers in the industry, leaving no stone unturned when it comes to securing projects and providing clients with their needs.
                                <br/><br/><br/>
                            </Grid>
                            <Grid item md={12} className={classes.btnInfo}>
                                <Button variant="contained" onClick={() => handleButton(0)} color="primary">
                                    Find Out More
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Fragment>
        </Container>
    </>
    );
}