
import { useSigner, useAddress } from "@thirdweb-dev/react";
import { createContext, useState, useEffect } from "react";
import { formatNumbers, formatWei } from './utils';
import { ethers } from "ethers";

export const Contracts = createContext(null);

let _useSigner = null;
let _useAddress = null;

export default function ContractsProvider({ children }) {
    const [daiContract, setDAIContract] = useState(null);
    const [z3Contract, setZ3Contract] = useState(null);
    const [lpContract, setLPContract] = useState(null);
    const [miniABI, setMiniABI] = useState(null);
    const [z3Price, setZ3Price] = useState(null);
    const [z3Total, setZ3Total] = useState(null);
    const [daiTotal, setDAITotal] = useState(null);
    const [treasuryTotal, setTreasuryTotal] = useState(null);
    const [zTotalSupply, setZ3TotalSupply] = useState(null);
    const Mini = require("./build/Mini.json");

    const contracts = {
        daiContract: daiContract,
        z3Contract: z3Contract,
        lpContract: lpContract,
        miniABI: miniABI,
        z3Price: z3Price,
        z3Total: z3Total,
        daiTotal: daiTotal,
        treasuryTotal: treasuryTotal,
        zTotalSupply: zTotalSupply
    };

    const loadContracts = async () => {
        let tmpSigner = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
        let miniABI = Mini.abi;

        setMiniABI(miniABI);

        let daiContract = new ethers.Contract("0x6B175474E89094C44Da98b954EedeAC495271d0F", miniABI, tmpSigner);
        let DAIAmount = await daiContract.balanceOf('0x4f013300A0DcE6193388Cd057108eecB9e1054aC');

        let z3Contract = new ethers.Contract("0x50Eec6D765792dCfB0913C8403ef2A12e1B861a6", miniABI, tmpSigner);
        let Z3Amount = await z3Contract.balanceOf('0x4f013300A0DcE6193388Cd057108eecB9e1054aC');
        let totalSupply = await z3Contract.totalSupply();
        let weiTotalSuply = formatWei(totalSupply).toString();
        let formattedTotalSupply = parseFloat(weiTotalSuply).toFixed(0);

        let lpContract = new ethers.Contract("0x856ff66843e9e7250b5e62ecac9d8d04c9a721c6", miniABI, tmpSigner);
        let LPPairReserves = await lpContract.getReserves();

        setDAIContract(daiContract);
        setZ3Contract(z3Contract);
        setLPContract(lpContract);

        let Z3Reserves = formatWei(LPPairReserves[0]);
        let DAIReserves = formatWei(LPPairReserves[1]);
        let Z3Price = DAIReserves / Z3Reserves;

        let z3Total = Z3Price * formatWei(Z3Amount);
        let daiTotal = 1 * formatWei(DAIAmount);
        let treasuryTotal = z3Total + daiTotal;

        setZ3Price(parseFloat(Z3Price.toString()).toFixed(4));
        setZ3Total(formatNumbers(parseFloat(z3Total).toFixed(0)));
        setDAITotal(formatNumbers(parseFloat(daiTotal).toFixed(0)));
        setTreasuryTotal(formatNumbers(parseFloat(treasuryTotal).toFixed(0)));
        setZ3TotalSupply(formatNumbers(formattedTotalSupply - 2100000));
    };

    useEffect(() => {
        loadContracts();
    }, [_useAddress]);

    _useSigner = useSigner();
    _useAddress = useAddress();

    return <Contracts.Provider value={contracts}>{children}</Contracts.Provider>
};