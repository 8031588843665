import { useAddress, useSigner } from "@thirdweb-dev/react";
import { useState, useEffect, Fragment } from 'react';
import { getEtherErrorMessage, fromWei, convertTimestampToDate, getAvailableNetworks } from '../../utils';
import { ethers } from "ethers";
import { useSnackbar } from "notistack";
import { TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { 
    Container, 
    Paper, 
    makeStyles, 
    Typography, 
    Grid, 
    InputLabel, 
    Table, 
    TableBody, 
    TableContainer, 
    TableHead, 
    TableRow, 
    TableCell, 
    styled,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    TextField as CustomField
} from "@material-ui/core";
import { Warning } from "@material-ui/icons";
import { tableCellClasses } from '@mui/material/TableCell';
import library from "../../Constants/contracts";
import * as chains from "../../Constants/chains";
import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';
import DateTimePicker from "../../Components/DateTimePicker";
import moment from "moment";
import './index.css';

let signer = null;
let connectedAddress = null;
let tmpSigner = null;
let lockerContract = null;

const Mini = require("../../build/Mini.json");

export default function Locker(props) {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: 'transparent',
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));
      
    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: 'transparent',
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const styles = (theme) => ({
        paperContainer: {
            padding: theme.spacing(5),
            paddingBottom: theme.spacing(3),
        },
        fullWidth: {
            width: "100%",
        },
        referralContainer: {
            marginTop: 20,
            paddingBottom: 20,
            borderBottom: "1px solid #464646"
        },
        referralContainer2: {
            marginTop: 20,
            paddingBottom: 20,
        },
        flex: {
            alignItems: "center",
            justifyContent: "space-evenly",
            display: "flex"
        },
        h4: {
            color: "#36c!important"
        },
        p: {
            margin: 0,
            display: "flex",
            color: "#f9e3b2",
            fontWeight: "100",
            alignItems: "center"
        },
        successBtn: {
            backgroundColor: "rgb(64, 133, 64)!important",
            color: "#fff!important",
            "&:hover": {
                backgroundColor: "rgb(49, 102, 49)!important",
            }
        },
        error : {
            color: "#f44336!important",
            fontWeight: "400",
            fontSize: "0.75rem",
            lineHeight: "1.66",
            letterSpacing: "0.03333rem",
            textAlign: "left",
            marginTop: "3px",
            marginRight: "14px",
            marginBottom: "0",
            marginLeft: "14px"
        },
    });

    const { enqueueSnackbar } = useSnackbar();
    const useStyles = makeStyles(styles);
    const classes = useStyles();

    const [isChecking, setIsChecking] = useState(false);
    const [isValidToken, setIsValidToken] = useState(false);
    const [tokenAddress, setTokenAddress] = useState(null);
    const [userLocks, setUserLocks] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(-1);
    const [withdrawIndex, setWithdrawIndex] = useState(-1);
    const [isWithdrawalDisabled, setIsWithrawalDisabled] = useState(true);
    const [isExtendLockTimeError, setExtendLockTimeError] = useState(false);
    const [extendLockTime, setExtendLockTime] = useState(null);
    const [ex_withdrawalTokens, setWithrawalTokens] = useState(0);
    const [ex_ownerAddress, setExtendOwnerAddress] = useState('');
    const [isExtending, setIsExtending] = useState(false);
    const [isWithdrawing, setIsWithdrawing] = useState(null);
    const [isChangingOwner, setChangingOwner] = useState(null);
    const [isOwner, setIsOwner] = useState(null);
    const [zevContracts, setZevContracts] = useState(null);

    const handleClose = () => {
        setModalOpen(false);
        setWithdrawIndex(-1);
    };

    const handleActions = (id, row) => {
        const selected = userLocks.find(o => o.id === id);

        if (selected) {
            if (selected.owner) {
                setExtendOwnerAddress(selected.owner);
            }

            if (selected.rawUnlockDate) {
                setExtendLockTime(dayjs(parseInt(selected.rawUnlockDate + "000")));
            }

            if (selected.rawLockedAmount) {
                setWithrawalTokens(fromWei(selected.rawLockedAmount, selected.decimals));
            }

            if (new Date().getTime() >= new Date(parseInt(selected.rawUnlockDate + "100")).getTime()) {
                setIsWithrawalDisabled(false);
            }
            else {
                setIsWithrawalDisabled(true);
            }

            setModalOpen(true);
            setSelectedId(id);
            setWithdrawIndex(row);
        }
        else {
            notify(0, "Could not find the selected lock");
        }
    };

    const notify = (flag, msg, delay = 10000) => {
        let type = "error";
  
        if (flag === 1) {
            type = "success";
        }
  
        enqueueSnackbar(msg, { variant: type, autoHideDuration: delay, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
        }});
    };

    const checkLockedTokens = async(token = tokenAddress, contracts = zevContracts) => {
        if (/^0x[a-fA-F0-9]{40}$/.test(token)) {
            setIsChecking(true);

            try {
                const tokenContract = new ethers.Contract(token, Mini.abi, tmpSigner);

                const name = await tokenContract.name();
                const symbol = await tokenContract.symbol();
                const decimals = await tokenContract.decimals();
                const owner = await tokenContract.owner();
                let balance = 0;

                if (connectedAddress) {
                    const tmpBalance = await tokenContract.balanceOf(connectedAddress);
                    balance = tmpBalance.toString();
                }

                const oldTokens = ["0xC6F7Dc9EeEF5CeE6D70a496e2F53c9c0525503b1", "0x6ea8189dE9e2D298bc129f4a0F981794423Cfd09"];
                const lockerAddress = oldTokens.indexOf(token) > -1 ? contracts[6].oldAddress : contracts[6].address;
                lockerContract = new ethers.Contract(lockerAddress, JSON.parse(contracts[6].abi), tmpSigner);

                const tokenBalance = fromWei(balance.toString(), decimals);
                const tmpUserLocks = await lockerContract.lpLocksForUser(owner);

                if (tmpUserLocks.length > 0) {
                    const zevFactory = new ethers.Contract(token, JSON.parse(contracts[0].abi), tmpSigner);
                    const lpPair = await zevFactory.lpPair();
                    const filteredArr = await lockerContract.getLocksForToken(lpPair, 0, 200000000);

                    let userLocks = [];

                    for (let i = 0; i < filteredArr.length; i++) {
                        const id = filteredArr[i].id;
                        const tokenAddress = filteredArr[i].token;
                        const lpOwner = filteredArr[i].owner;

                        if (connectedAddress === lpOwner) {
                            setIsOwner(true);
                        }
                        else {
                            setIsOwner(false);
                        }
            
                        const tokenContract = new ethers.Contract(tokenAddress, Mini.abi, tmpSigner);
                        const lpName = await tokenContract.name();
                        const lockDate = filteredArr[i].lockDate.toString();
                        const tgeDate = filteredArr[i].tgeDate.toString();
                        const lockedAmount = fromWei(filteredArr[i].amount.toString(), decimals);
                        const unlockedAmount = fromWei(filteredArr[i].unlockedAmount.toString(), decimals);

                        userLocks.push({
                            'id' : id,
                            'name' : lpName,
                            'lockDate' : convertTimestampToDate(lockDate),
                            'unlockDate' : convertTimestampToDate(tgeDate),
                            'lockedAmount' : parseFloat(lockedAmount.toString()).toFixed(4) + " " + symbol + " LP Tokens",
                            'unlockedAmount' : parseFloat(unlockedAmount.toString()).toFixed(4) + " " + symbol + " LP Tokens",
                            'description' : filteredArr[i].description,
                            'rawUnlockDate' : tgeDate,
                            'rawLockedAmount' : filteredArr[i].amount.toString(),
                            'owner' : lpOwner,
                            'decimals' : decimals,
                            'token' : name,
                            'balance' : tokenBalance + " " + symbol
                        });
                    }
            
                    setUserLocks(userLocks);
                    setIsValidToken(true);
                }
                else {
                    setIsValidToken(false);
                    notify(0, "Could not get data from the provided token address");
                }

                setIsChecking(false);
            }
            catch(err) {
                notify(0, "Could not get data from the provided token address");
                console.log(err);
                setIsValidToken(false);
                setIsChecking(false);
            }
        }
        else {
            notify(0, "Invalid ETH Address");
            setIsValidToken(false);
        }
    };

    const extendWithdrawal = async(data) => {
        const tmpLockTime = new Date(data.ex_lockTime).getTime();
        const lockTime = parseInt(tmpLockTime / 1000);
        const selected = userLocks.find(o => o.id === selectedId);

        if (!selected) { return; }

        try {
            setIsExtending(true);

            const lockerTransaction = await lockerContract.editLock(selectedId, selected.rawLockedAmount, lockTime);

            await lockerTransaction.wait().then(async(result) => {
                notify(1, "Successfully updated the lock!");
            });

            setModalOpen(false);
            setWithdrawIndex(-1);
            checkLockedTokens();
            setIsExtending(false);
        } 
        catch(err) {
            console.log(err);
            notify(0, 'Error: ' + getEtherErrorMessage(err));
            setIsExtending(false);
        }
    };

    const handleOwnerChange = async() => {
        const selected = userLocks.find(o => o.id === selectedId);
        if (!selected) { return; }

        try {
            if (/^0x[a-fA-F0-9]{40}$/.test(ex_ownerAddress)) {
                if (selected.owner != ex_ownerAddress) {
                    setChangingOwner(true);

                    const ownershipTransaction = await lockerContract.transferLockOwnership(selectedId, ex_ownerAddress);
        
                    await ownershipTransaction.wait().then(async(result) => {
                        notify(1, "Successfully transferred ownership");
                        setModalOpen(false);
                        setWithdrawIndex(-1);
                        checkLockedTokens();
                        setChangingOwner(false);
                    });
                }
                else {
                    notify(0, 'The owner address is not different');
                }
            }
            else {
                notify(0, 'THe owner address is not valid');
            }
        } 
        catch(err) {
            console.log(err);
            notify(0, 'Error: ' + getEtherErrorMessage(err));
            setChangingOwner(false);
        }
    };

    const handleWithdraw = async() => {
        try {
            setIsWithdrawing(true);
            const transaction = await lockerContract.unlock(selectedId);

            await transaction.wait().then(async(result) => {
                notify(1, "Successfully withdrawn the locked tokens");
                checkLockedTokens();
                setModalOpen(false);
                setWithdrawIndex(-1);
                setIsWithdrawing(false);
            });
        } 
        catch(err) {
            console.log(err);
            notify(0, 'Error: ' + getEtherErrorMessage(err));
            setIsWithdrawing(false);
        }
    };

    const initialise = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const chainIdRef = queryParams.get("chainId");

        if (!signer) {
            tmpSigner = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
        }
        else {
            tmpSigner = signer;
        }

        let chainId = 1;

        if (signer) {
            chainId = signer.provider.network.chainId;
        }
        else {
            if (/^[1-9]\d{0,2}(?:\,\d{1,3})?$/.test(chainIdRef)) {
                chainId = parseInt(chainIdRef);
            }
        }

        if (chains.networks.includes(chainId)) {
            let tmpContracts = library.get(chainId);

            if (tmpContracts) {
                setZevContracts(tmpContracts);
                
                const refCode = queryParams.get("token");
  
                if (/^0x[a-fA-F0-9]{40}$/.test(refCode)) {
                    setTokenAddress(refCode);
                    checkLockedTokens(refCode, tmpContracts);
                }
                else {
                    if (tokenAddress != null) {
                        checkLockedTokens(tokenAddress, tmpContracts);
                    }
                }
            }
            else {
                notify(0, "Please connect to the following networks: " + getAvailableNetworks(chains.networks));
            }
        }
        else {
            notify(0, "Please connect to the following networks: " + getAvailableNetworks(chains.networks));
        }
    };

    useEffect(() => {
        initialise();
    }, [connectedAddress]);

    signer = useSigner();
    connectedAddress = useAddress();

    return(
    <>
        <Container maxWidth="md" className="lockerContainer">
            <Fragment>
                <Paper className={classes.paperContainer}>
                    <Typography variant="h4" className={classes.title}>
                        Manage Liquidity Locker³
                    </Typography>
                    <Grid container spacing={2} className={classes.referralContainer}>
                        <Grid item md={9} xs={12} >
                            <CustomField fullWidth value={tokenAddress ? tokenAddress : '' } onChange={(e) => setTokenAddress(e.target.value)} variant="outlined" name="tokenAddress" type="string" label="Enter Token Address" placeholder="0x..." />
                        </Grid>
                        <Grid item md={3} xs={12} className={classes.flex}>
                            <Button onClick={() => checkLockedTokens()} startIcon={isChecking ? <CircularProgress style={{'color': '#fff'}} size="1rem" /> : null} disabled={isChecking} variant="contained" color="primary">{isChecking ? "Checking..." : "Check Token"}</Button>
                        </Grid>
                    </Grid>
                    {isValidToken ? 
                        <Grid container spacing={2} className={classes.referralContainer2}>
                            {userLocks.length > 0 ? 
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="Locked Tokens Table">
                                        <TableHead>
                                            <StyledTableRow>
                                                <StyledTableCell align="left">Token</StyledTableCell>
                                                <StyledTableCell align="center">Pair</StyledTableCell>
                                                <StyledTableCell align="center">Locked Amount</StyledTableCell>
                                                <StyledTableCell align="center">Unlocked Amount</StyledTableCell>
                                                <StyledTableCell align="center">Balance Amount</StyledTableCell>
                                                <StyledTableCell align="center">Description</StyledTableCell>
                                                <StyledTableCell align="center">Locked Time</StyledTableCell>
                                                <StyledTableCell align="center">Unlock Time</StyledTableCell>
                                                <StyledTableCell align="center">Actions</StyledTableCell>
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {userLocks.map((row, r) => {
                                                const id = row.id;
                                                const token = row.token;
                                                const name = row.name;
                                                const lockDate = row.lockDate;
                                                const unlockDate = row.unlockDate;
                                                const lockedAmount = row.lockedAmount;
                                                const unlockedAmount = row.unlockedAmount;
                                                const balanceAmount = parseFloat(row.balance).toFixed(4);
                                                const description = row.description;
                                                const tmpIsWithdrawing = r === withdrawIndex;

                                                return (
                                                    <StyledTableRow
                                                        key={row.id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 }, '&:nth-of-type(odd)': { backgroundColor: '#dcdcdc' }}}
                                                    >
                                                        <StyledTableCell data-label="Token" align="left">{token}</StyledTableCell>
                                                        <StyledTableCell data-label="Pair" align="center">{name}</StyledTableCell>
                                                        <StyledTableCell data-label="Locked Amount" align="center">{lockedAmount}</StyledTableCell>
                                                        <StyledTableCell data-label="Unlocked Amount" align="center">{unlockedAmount}</StyledTableCell>
                                                        <StyledTableCell data-label="Balance Amount" align="center">{balanceAmount}</StyledTableCell>
                                                        <StyledTableCell data-label="Description" align="center">{description}</StyledTableCell>
                                                        <StyledTableCell data-label="Locked Time" align="center">{lockDate}</StyledTableCell>
                                                        <StyledTableCell data-label="Unlock Time" align="center">{unlockDate}</StyledTableCell>
                                                        <StyledTableCell data-label="Actions" align="center">{tmpIsWithdrawing ? <CircularProgress style={{'color': '#fff'}} size="1rem" /> : <Button disabled={isOwner ? false : true} startIcon={tmpIsWithdrawing ? <CircularProgress style={{'color': '#fff'}} size="1rem" /> : null} variant="contained" color="primary" onClick={() => handleActions(id, r)}>Configure</Button>}</StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            :  <Grid item xs={12} className="warningContainer"><p className={classes.p}><Warning/> There are no tokens locked in this LP pool</p></Grid>}
                            
                        </Grid>
                    : 
                        <Grid container spacing={2} className={classes.referralContainer}>
                            <Grid item xs={12} className="warningContainer">
                                <p className={classes.p}><Warning/> Enter a valid token address above to check for any locked tokens</p>
                            </Grid>
                        </Grid>
                    }
                    <Dialog open={modalOpen} onClose={handleClose} className="locked-dialog">
                        <div className={classes.modal}>
                            <DialogTitle>Configure Locked Tokens</DialogTitle>
                            <DialogContent>
                                <Formik
                                    initialValues={{
                                        ex_ownerAddress: '',
                                        ex_lockTime: extendLockTime ? extendLockTime : ''
                                    }}
                                    validationSchema={
                                        Yup.object().shape({
                                            ex_ownerAddress: Yup.string()
                                            .test('ownerTest2', 'Owner address is not valid', function(item) {
                                                return /^0x[a-fA-F0-9]{40}$/.test(ex_ownerAddress) ? true : false
                                            }),
                                            ex_lockTime: Yup.string()
                                            .required('Lock Time is required')
                                            .test('dateTest2', 'Lock Time must be in the future', function(dateTime) {
                                                if (dateTime) {
                                                    var date = moment(dateTime);
                                                    var dateNow = moment();
                                                    var dDiff = date.diff(dateNow);
        
                                                    if (dDiff > 0) {
                                                        setExtendLockTimeError(false);
                                                        return true;
                                                    }
                                                    else {
                                                        setExtendLockTimeError(true);
                                                        return false;
                                                    }
                                                }
                                            })
                                    })}
                                    onSubmit={extendWithdrawal}
                                >
                                    {({ errors, touched }) => (
                                        <Form>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={9}>
                                                    <Field fullWidth variant="outlined" name="ex_ownerAddress" type="text" component={TextField} label="Owner Address" onChange={e => setExtendOwnerAddress(e.target.value)} value={ex_ownerAddress ? ex_ownerAddress : ''} />
                                                </Grid>
                                                <Grid item xs={12} sm={3} className='center'>
                                                    <Button className={classes.fullWidth} variant="contained" color="primary" onClick={(e) => handleOwnerChange()}  disabled={isChangingOwner}>{isChangingOwner ? <CircularProgress style={{'color': '#fff'}} size="24px" /> : "Update"}</Button>
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    <DateTimePicker name="ex_lockTime" label="Extend Lock" />                                       
                                                    {isExtendLockTimeError ? <InputLabel className={classes.error}>The lock time must be in the future</InputLabel> : ''}
                                                </Grid>
                                                <Grid item xs={12} sm={3} className='center'>
                                                    <Button className={classes.fullWidth} variant="contained" color="primary" type="submit"  disabled={isExtending}>{isExtending ? <CircularProgress style={{'color': '#fff'}} size="24px" /> : "Extend"}</Button>
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    <Field fullWidth variant="outlined" name="ex_tokens" disabled type="number" component={TextField} label="Withdrawable Tokens" value={ex_withdrawalTokens ? ex_withdrawalTokens : ''} />
                                                </Grid>
                                                <Grid item xs={12} sm={3} className='center'>
                                                    <Button className={[classes.fullWidth, classes.successBtn]} variant="contained" disabled={isWithdrawalDisabled} onClick={(e) => handleWithdraw()}>{isWithdrawing ? <CircularProgress color="#fff" size="24px" /> : "Withdraw"}</Button>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    )}
                                </Formik>
                            </DialogContent>
                        </div>
                    </Dialog>
                </Paper>
            </Fragment>
        </Container>
    </>
    );
}