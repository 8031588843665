import React, {Fragment } from 'react';
import { 
    Container,
    Paper,
    Typography,
    makeStyles
} from "@material-ui/core";


function ComingSoon() {
    const styles = (theme) => ({
        paperContainer: {
            paddingTop: "0px",
            height: "50%",
            width: "70%",
            margin: "auto",
            textAlign: "center"
        },
        title: {
            padding: theme.spacing(0.5),
            marginBottom: theme.spacing(1),
            display: "block"
        },
        underConstruction: {
            marginBottom: "50px"
        }
    });

    const useStyles = makeStyles(styles);
    const classes = useStyles();

    return(
        <>
            <Container maxWidth="md">
                <Fragment>
                    <Paper className={classes.paperContainer}>
                        <img alt='' src='under-construction.png' className={classes.underConstruction}/> 
                        <Typography variant="h5" className={classes.title}>
                            This page is coming soon!
                        </Typography>
                        <Typography variant="h6" className={classes.title}>
                            Stay tuned for updates in our telegram channel
                        </Typography>
                    </Paper>
                </Fragment>
            </Container>
        </>
    );
}

export default ComingSoon;